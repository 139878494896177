import { httpClient,httpEnrollmentClient,httpCMSAnalyticsClient, httpClientEnrollment} from "../common/httpClient";
import API_CONST from '@/constants/ApiConst';

import { IAdaWeekLinePayload } from "@/Model/model";
import APP_CONST from "@/constants/AppConst";
import APP_UTILITIES from "@/utilities/commonFunctions";

export function programRecruitment(accountId: any) {
    return httpClient().get(`${API_CONST.ANALYTICS_PROGRAM_RECRUITMENT}/${accountId}`).then((response) => {
      return { data : response.data, status: response.status, statusText: response.statusText};
    }).catch((error) => {
      return { data : error.data, status: error.status, statusText: error.statusText};
    });
  }

  export function programSites(programId: any) {
    return httpClient().get(`${API_CONST.PROGRAM_SESSIONS_LIST}/${programId}`).then((response) => {
      return { data : response.data, status: response.status, statusText: response.statusText};
    }).catch((error) => {
      return { data : error.data, status: error.status, statusText: error.statusText};
    });
  }

  export function applicationSites(programId: any) {
    return httpEnrollmentClient().get(`${API_CONST.PROGRAM_SITES_APPLICATION}${programId}`).then((response) => {
      return { data : response.data, status: response.status, statusText: response.statusText};
    }).catch((error) => {
      return { data : error.data, status: error.status, statusText: error.statusText};
    });
  }

  export function programSitesAnalyticsDetail(programId: any, siteIds:any) {
    return httpCMSAnalyticsClient().get(`${API_CONST.PROGRAM_SITES_ANALYTIC_LIST}?programId=${programId}&siteIds=${siteIds}`).then((response) => {
      return { data : response.data, status: response.status, statusText: response.statusText};
    }).catch((error) => {
      return { data : error.data, status: error.status, statusText: error.statusText};
    });
  }

  export function applicationAnalyticsDetail(programId: any, applicationIds:any) {
    applicationIds = applicationIds && applicationIds.length && applicationIds.map((element:string) => {
      return element.toUpperCase();
    });
    let applicationId = applicationIds && applicationIds.length ? applicationIds.join(',') : APP_CONST.BLANK;
    return httpCMSAnalyticsClient().get(`${API_CONST.APPLICATION_ANALYTIC_LIST}?programId=${programId}&applicationIds=${applicationId}`).then((response) => {
      return { data : response.data, status: response.status, statusText: response.statusText};
    }).catch((error) => {
      return { data : error.data, status: error.status, statusText: error.statusText};
    });
  }

  export function programSitesGroupsList(programId:number, siteId:number){
    let highestRole: number = JSON.parse(JSON.stringify(APP_UTILITIES.getCookie("highest_role")));
    let unassignedgroups: boolean = true;
    if (highestRole == APP_CONST.STAFF_ROLE_ID) {
      unassignedgroups = false;
    }
    return httpClient().get(`/sites/${siteId}/program/${programId}/classrooms?includeUnassignedScholars=${unassignedgroups}`).then((response) => {
      return { data : response.data, status: response.status, statusText: response.statusText};
    }).catch((error) => {
      return { data : error.data, status: error.status, statusText: error.statusText};
    });
  }
  export function getRetentionWeekLineData(params :IAdaWeekLinePayload ) {
    return httpCMSAnalyticsClient().get(`${API_CONST.ANALYTICS_RETENTION}?programId=${params.programId}&siteId=${params.siteId}&groupIds=${params.groupIds}&isDay=${params.isDay}`).then((response) => {
      return { data : response.data, status: response.status, statusText: response.statusText};
    }).catch((error) => {
      return { data : error.data, status: error.status, statusText: error.statusText};
    });

}

export function applicationSubmissionByStatus(payload: any) {
  payload.applicationIds = payload.applicationIds && payload.applicationIds.length && payload.applicationIds.map((element:string) => {
    return element.toUpperCase();
  });
  let applicationId = payload.applicationIds && payload.applicationIds.length ? payload.applicationIds.join(',') : APP_CONST.BLANK;
  var url = `${API_CONST.SCHOLARS_APPLICATIONS_DROPDOWN_SUBMISSION_STATUS}programid/${payload.programId}?applicationIds=${applicationId}`;
  return httpClientEnrollment().get(url).then((response: any) => {
    const temp = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    let fileName = response.headers["content-disposition"] && ((response.headers["content-disposition"].split(';'))[1].split("filename=")[1]).replace(/['"]+/g, '') || 'download.csv';
    link.href = temp;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.target = "_blank"
    link.click();
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function totalWeeklySubmissions(payload: any) {
  var url = `${API_CONST.SCHOLARS_APPLICATIONS_DROPDOWN_SUBMISSION_WEEKLY}programid/${payload.programId}?applicationIds=${payload.applicationIds}`;
  return httpClientEnrollment().get(url).then((response: any) => {
    const temp = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    let fileName = response.headers["content-disposition"] && ((response.headers["content-disposition"].split(';'))[1].split("filename=")[1]).replace(/['"]+/g, '') || 'download.csv';
    link.href = temp;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.target = "_blank"
    link.click();
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function adabySite(payload: any) {
  var url = `${API_CONST.SCHOLARS_ATTENDANCE_DROPDOWN_SITE}?programId=${payload.programId}`;
  return httpClient().get(url).then((response: any) => {
    const temp = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    let fileName = response.headers["content-disposition"] && ((response.headers["content-disposition"].split(';'))[1].split("filename=")[1]).replace(/['"]+/g, '') || 'download.csv';
    link.href = temp;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.target = "_blank"
    link.click();
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function adabyWeek(payload: any) {
  var url = `${API_CONST.SCHOLARS_ATTENDANCE_DROPDOWN_WEEK}?programId=${payload.programId}&siteId=${payload.siteId}&groupIds=${payload.groupIds}&isDay=${payload.isDay}`;
  return httpClient().get(url).then((response: any) => {
    const temp = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    let fileName = response.headers["content-disposition"] && ((response.headers["content-disposition"].split(';'))[1].split("filename=")[1]).replace(/['"]+/g, '') || 'download.csv';
    link.href = temp;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.target = "_blank"
    link.click();
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function adabyDay(payload: any) {
  var url = `${API_CONST.SCHOLARS_ATTENDANCE_DROPDOWN_DAY}?programId=${payload.programId}&siteId=${payload.siteId}&groupIds=${payload.groupIds}&isDay=true`;
  return httpClient().get(url).then((response: any) => {
    const temp = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    let fileName = response.headers["content-disposition"] && ((response.headers["content-disposition"].split(';'))[1].split("filename=")[1]).replace(/['"]+/g, '') || 'download.csv';
    link.href = temp;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.target = "_blank"
    link.click();
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function attendanceScholarCheckin(payload: any) {
  var url = `${API_CONST.SCHOLARS_ATTENDANCE_DROPDOWN_CHECK_IN}?programId=${payload.programId}&siteId=${payload.siteId}&groupIds=${payload.groupIds}`;
  return httpClient().get(url).then((response: any) => {
    const temp = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    let fileName = response.headers["content-disposition"] && ((response.headers["content-disposition"].split(';'))[1].split("filename=")[1]).replace(/['"]+/g, '') || 'download.csv';
    link.href = temp;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.target = "_blank"
    link.click();
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function attendanceScholarCheckout(payload: any) {
  var url = `${API_CONST.SCHOLARS_ATTENDANCE_DROPDOWN_CHECK_OUT}?programId=${payload.programId}&siteId=${payload.siteId}&groupIds=${payload.groupIds}`;
  return httpClient().get(url).then((response: any) => {
    const temp = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    let fileName = response.headers["content-disposition"] && ((response.headers["content-disposition"].split(';'))[1].split("filename=")[1]).replace(/['"]+/g, '') || 'download.csv';
    link.href = temp;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.target = "_blank"
    link.click();
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

  export function completionSiteAnalyticsDetail(programId:number, siteId:number|string, groupIds?:any, selectedTab?:any){
    siteId = siteId?siteId:APP_CONST.ZERO;
    groupIds = !groupIds?[]:groupIds
    selectedTab = (selectedTab && (selectedTab=='Day'))?true: false
    return httpCMSAnalyticsClient().get(`${API_CONST.ATTENDENCE_SITE_ANALYTICS_LIST}?programId=${programId}&siteId=${siteId}&groupIds=${groupIds}&isDay=${selectedTab}`).then((response) => {
      return { data : response.data, status: response.status, statusText: response.statusText};
    }).catch((error) => {
      return { data : error.data, status: error.status, statusText: error.statusText};
    });
  }

  export function getAdaProgramData(programId: number) {
    return httpCMSAnalyticsClient().get(`${API_CONST.ANALYTICS_ADA}/program/${programId}`).then((response) => {
        return { data : response.data, status: response.status, statusText: response.statusText};
      }).catch((error) => {
        return { data : error.data, status: error.status, statusText: error.statusText};
    });
  }

  export function getAttenCompletionProgramData(programId: any) {
    return httpCMSAnalyticsClient().get(`${API_CONST.ATTENDENCE_COMPLETION_PROGRAM_DETAILS}/${programId}`).then((response) => {
      return { data : response.data, status: response.status, statusText: response.statusText};
    }).catch((error) => {
      return { data : error.data, status: error.status, statusText: error.statusText};
    });
  }

  export function getRetentionProgramData(programId: number) {
    return httpCMSAnalyticsClient().get(`${API_CONST.ANALYTICS_RETENTION}/program/${programId}`).then((response) => {
        return { data : response.data, status: response.status, statusText: response.statusText};
      }).catch((error) => {
        return { data : error.data, status: error.status, statusText: error.statusText};
    });
  }

  export function qualityAnalyticsDetail(programId: any, siteId:number) {
    return httpCMSAnalyticsClient().get(`${API_CONST.QUALITY_ANALYTIC_LIST}?programId=${programId}&siteId=${siteId}`).then((response) => {
      return { data : response.data, status: response.status, statusText: response.statusText};
    }).catch((error) => {
      return { data : error.data, status: error.status, statusText: error.statusText};
    });
  }

  export function completionCSVDownload(payload:any) {
    return httpCMSAnalyticsClient().get(`${API_CONST.COMPLETION_DOWNLOAD_CSV}?programId=${payload.programId}&siteId=${payload.siteId}&groupIds=${payload.groupIds}&isDay=${payload.isDay}&csvType=${payload.csvType}`).then((response) => {
      const temp = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    let fileName = response.headers["content-disposition"] && ((response.headers["content-disposition"].split(';'))[1].split("filename=")[1]).replace(/['"]+/g, '') || 'download.csv';
    link.href = temp;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.target = "_blank"
    link.click();
      return { data : response.data, status: response.status, statusText: response.statusText};
    }).catch((error) => {
      return { data : error.data, status: error.status, statusText: error.statusText};
    });
  }

  export function retensionCSVDownload(payload:any) {
    return httpCMSAnalyticsClient().get(`${API_CONST.RETENSION_DOWNLOAD_CSV}?csvType=${payload.csvType}&programId=${payload.programId}&siteId=${payload.siteId}&groupIds=${payload.groupIds}&isDay=${payload.isDay}`).then((response) => {
    const temp = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    let fileName = response.headers["content-disposition"] && ((response.headers["content-disposition"].split(';'))[1].split("filename=")[1]).replace(/['"]+/g, '') || 'download.csv';
    link.href = temp;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.target = "_blank"
    link.click();
      return { data : response.data, status: response.status, statusText: response.statusText};
    }).catch((error) => {
      return { data : error.data, status: error.status, statusText: error.statusText};
    });
  }

  export function professionalLearningCSVDownload(payload:any) {
    return httpCMSAnalyticsClient().get(`${API_CONST.PROFESSIONAL_LEARNING_DOWNLOAD_CSV}?programIds=${payload.programIds}`).then((response) => {
    const temp = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    let fileName = response.headers["content-disposition"] && ((response.headers["content-disposition"].split(';'))[1].split("filename=")[1]).replace(/['"]+/g, '') || 'download.csv';
    link.href = temp;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.target = "_blank"
    link.click();
      return { data : response.data, status: response.status, statusText: response.statusText};
    }).catch((error) => {
      return { data : error.data, status: error.status, statusText: error.statusText};
    });
  }
export function consolidatedScholarCSVDownload(payload:any)
{
  return httpEnrollmentClient().post(`${API_CONST.DOWNLOAD_ROASTER}?accountId=${payload.accountId}&siteId=${payload.siteId}&programId=${payload.programId}&step=-1&filetype=2`,
    {
      // It needs to be present in the request body
      ColumnsToHide: null
    }
  ).then((response) => {
    const temp = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    let fileName = response.headers["content-disposition"] && ((response.headers["content-disposition"].split(';'))[1].split("filename=")[1]).replace(/['"]+/g, '') || 'download.csv';
    link.href = temp;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.target = "_blank"
    link.click();
      return { data : response.data, status: response.status, statusText: response.statusText};
    }).catch((error) => {
      return { data : error.data, status: error.status, statusText: error.statusText};
    });

}
  export function enrollmentCSVDownload(payload:any) {
    return httpCMSAnalyticsClient().get(`${API_CONST.ENROLLMENT_DOWNLOAD_CSV}?programId=${payload.programId}&siteIds=${payload.siteId}`).then((response) => {
    const temp = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    let fileName = response.headers["content-disposition"] && ((response.headers["content-disposition"].split(';'))[1].split("filename=")[1]).replace(/['"]+/g, '') || 'download.csv';
    link.href = temp;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.target = "_blank"
    link.click();
      return { data : response.data, status: response.status, statusText: response.statusText};
    }).catch((error) => {
      return { data : error.data, status: error.status, statusText: error.statusText};
    });
  }

  export function getProfessionalLearningDetails(programIds:number[]){
    return httpCMSAnalyticsClient().get(`${API_CONST.PROFESSIONAL_LEARNING_DETAILS}?programIds=${programIds}`).then((response) => {
      return { data : response.data, status: response.status, statusText: response.statusText};
    }).catch((error) => {
      return { data : error.data, status: error.status, statusText: error.statusText};
    });
  }

  export function adaScholarDetailCSVDownload(payload:any){
    return httpClient().get(`${API_CONST.ADA_SCHOLAR_DETAILS_DOWNLOAD_CSV}?programId=${payload.programId}&siteId=${payload.siteId}&groupIds=${payload.groupIds}`).then((response) => {
      const temp = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      let fileName = response.headers["content-disposition"] && ((response.headers["content-disposition"].split(';'))[1].split("filename=")[1]).replace(/['"]+/g, '') || 'download.csv';
      link.href = temp;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.target = "_blank"
      link.click();
        return { data : response.data, status: response.status, statusText: response.statusText};
      }).catch((error) => {
        return { data : error.data, status: error.status, statusText: error.statusText};
      });
  }

  export function scholarDailyAttendanceCSVDownload(payload:any){
    return httpClient().get(`${API_CONST.SCHOLAR_DAILY_ATTENDANCE_DOWNLOAD_CSV}?programId=${payload.programId}&siteId=${payload.siteId}&groupIds=${payload.groupIds}`).then((response) => {
      const temp = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      let fileName = response.headers["content-disposition"] && ((response.headers["content-disposition"].split(';'))[1].split("filename=")[1]).replace(/['"]+/g, '') || 'download.csv';
      link.href = temp;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.target = "_blank"
      link.click();
        return { data : response.data, status: response.status, statusText: response.statusText};
      }).catch((error) => {
        return { data : error.data, status: error.status, statusText: error.statusText};
      });
  }