import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule
} from 'vuex-module-decorators';
import { CsvPayload_Week_Day, IAdaWeekLinePayload, IApplicationPayload, ICsvPayload, IGroupsBySiteid, centerData, doughnutDataFormat, familyEnagementFamilyData, familyEnagementTeamData, responseStatus } from '@/Model/model';
import { adaScholarDetailCSVDownload, adabyDay, adabySite, adabyWeek, applicationAnalyticsDetail, applicationSites, attendanceScholarCheckin, attendanceScholarCheckout, completionCSVDownload, completionSiteAnalyticsDetail, consolidatedScholarCSVDownload, enrollmentCSVDownload, getAdaProgramData, getAttenCompletionProgramData, getProfessionalLearningDetails, getRetentionProgramData, getRetentionWeekLineData, professionalLearningCSVDownload, programRecruitment, programSites, programSitesAnalyticsDetail, programSitesGroupsList, qualityAnalyticsDetail, retensionCSVDownload, scholarDailyAttendanceCSVDownload } from '@/services/analytics/analyticsService';
import { getAdaWeekLineData, getGroupsBySiteid, getRoleWiseUsersByProgramId } from '@/services/userService/users-api';
import { getApplicationData, getFamilyEngagementTeamData } from '@/services/dashboard/dashboardService';

import APP_CONST from '@/constants/AppConst';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { getFamilyEngagementFamilyData } from '../../services/dashboard/dashboardService';
import store from '@/store';

@Module({
  namespaced: true,
  name: 'analytics',
  store,
  dynamic: true
})

class AnalyticsModule extends VuexModule {

  public selectedScholarNavigationtab: string = '';
  public selectedAttendanceAnalyticsTab: string = '';
  public selectedSiteSessionAnalyticsTab: string = 'Week';
  public selectedFamilyEngagementTab: string = '';
  public programDropdownValue: any = { allPrograms: [], activePrograms: [], disabledPrograms: [] };
  public selectedProgramData: any = {};
  public programSitesData: any = [];
  public retentionProgramSitesData: any = [];
  public disableButton: boolean = false;
  public listOptions: any = APP_CONST.SCHOLARS_CSV_ATTENDANCE_LIST;
  public siteSelected: any = { id: 0, value: '' };
  public siteGroupsData: any = [];
  public sitesAnalyticsData: any = [];
  public dropdownResponseStatus: number = 0;
  public isDoughNutEmptyState: boolean = false;
  public isAnalyticsResponseReceived: boolean = false;
  public doughnutDataFormat: doughnutDataFormat = {
    Declined: 0,
    Waitlist: 0,
    Pending: 0
  };
  public dataloaded: boolean = false;
  public dropdownDisabled: boolean = true;
  public centerTextDataObj: centerData = {
    name: 0,
    value: APP_CONST.TOTAL_SUBMITTED
  };
  public getApplicationResponseStatus: responseStatus = { responseStatus: 0 };
  public applicationSitesData: any = [];
  public applicationSelected: any = [];
  public colorsList: Array<string> = APP_CONST.COLORS_LINE_CHART;
  public chartDataFromAPI: any = { labels: [], datasets: [] };
  public responseReceived: boolean = false;
  public isAllSelectSiteRequired: boolean = true;
  public groupsList: any = [];
  public selectedSite: any = '';
  public attendenceCompletionData: any = { datasets: [], labels: [] };
  public selectedGroupIds: any = [];
  public adaSettingObj: any = {};
  public authKey: string = '';
  public retentionSetitngsObj: any = {};
  public qualityPercentage: string = '--';
  public enrollmentSettingObj: any = {};
  public applicationObj: any = {};
  public completionCSVlistOptions: any = APP_CONST.SCHOLARS_CSV_COMPLETION_LIST;
  public disableButtonCompletion: boolean = false;
  public chartDataFromAPIQuality: any = { labels: [], datasets: [] };
  public categories: any = [];
  public profLearningRoles: any = JSON.parse(JSON.stringify(APP_CONST.PROF_LEARNING_ROLES_LIST));
  public profLearningData: any = { labels: [], datasets: [], totalCount: '--' };
  public completeProfLearningData: any = { labels: [], datasets: [] };
  public retensionCSVlistOptions: any = APP_CONST.SCHOLARS_CSV_RETENSION_LIST;
  public disableButtonRetension: boolean = false;
  public enrollmentCSVlistOptions: any = APP_CONST.SCHOLARS_CSV_ENROLLMENT_LIST;
  public disableButtonEnrollment: boolean = false;
  public disableButtonConsolidatedRoster: boolean = false;
  public disableButtonProfessionalLearning: boolean = false;
  public isEnrollmentLoaderVisible: boolean = false;
  public isCompletionLineChartVisible: boolean = false;

  public familyEnagementFamilyData: familyEnagementFamilyData = {
    activeMobileUsers: 0,
    totalActiveAccounts: 0,
    totalDirectMessages: 0,
    totalLikedPosts: 0
  };
  public familyEnagementTeamData: familyEnagementTeamData = {
    totalActiveMobileUsers: 0,
    totalMessagesToGuardians: 0,
    totalMessagesToTeamMembers: 0,
    totalPosts: 0
  };
  public adaWeekGraphDAta: any = { labels: [], datasets: [] };
  public retentionGraphData: any = { labels: [], datasets: [] };
  public adaBarData: any = [];
  public retentionBarData: any = [];
  public adaProgramPercent: string = '--';
  public retentionProgramPercent: string = '--';
  public completionProgramPercent: string = '--';
  public roleCounts: any = { 5: 0, 6: 0, 7: 0 };
  public roleNames: any = { 5: 'Program Admin', 6: 'Session Admin', 7: 'Staff' };
  public roleColors: any = { 5: '#ed589d', 6: '#6c63f0', 7: '#3aacff' };
  public profLearningSettingsObj: any = {};
  public qualitySetitngsObj: any = {};
  public disableAdaProgramdropdown: boolean = false;
  public isLineChartLoaderVisible: boolean = false;
  public isCompletionBarChartLoaderVisible: boolean = false;

  get isADAProgramdropdownDisabled() {
    return this.disableAdaProgramdropdown;
  }

  get selectedGroupIdsList() {
    return this.selectedGroupIds;
  }

  get isEnrollmentLoader() {
    return this.isEnrollmentLoaderVisible;
  }

  get selectedSiteData() {
    return this.selectedSite;
  }

  get isLineChartCompletionVisible() {
    return this.isCompletionLineChartVisible;
  }

  get siteGroupsList() {
    return this.groupsList;
  }
  get totalQualityPercent() {
    return this.qualityPercentage;
  }

  get getComplProgramPercentage() {
    return this.completionProgramPercent;
  }

  get familyEnagementFamilyDataObj() {
    return this.familyEnagementFamilyData;
  }

  get adaProgramPercentage() {
    return this.adaProgramPercent;
  }

  get retentionProgramPercentage() {
    return this.retentionProgramPercent;
  }

  get adaBarGraphData() {
    return this.adaBarData;
  }

  get retentionBarGraphData() {
    return this.retentionBarData;
  }

  get familyEnagementTeamDataObj() {
    return this.familyEnagementTeamData;
  }
  get adaWeekLineData() {
    return this.adaWeekGraphDAta;
  }

  get adaCsvDropdown() {
    return this.listOptions;
  }

  get retentionLineData() {
    return this.retentionGraphData;
  }

  get selectedScholarNavigationtabValue() {
    return this.selectedScholarNavigationtab;
  }

  get selectedAttendanceAnalyticsTabValue() {
    return this.selectedAttendanceAnalyticsTab;
  }

  get selectedSiteSessionAnalyticsTabValue() {
    return this.selectedSiteSessionAnalyticsTab;
  }

  get selectedFamilyEngagementTabValue() {
    return this.selectedFamilyEngagementTab;
  }

  get analyticsProgramDropdown() {
    return this.programDropdownValue;
  }

  get selectedprogramSites() {
    return this.programSitesData;
  }

  get selectedprogramSitesRetention() {
    return this.retentionProgramSitesData;
  }

  get disableDownloadButton() {
    return this.disableButton;
  }

  get applicationListResponseRecieved() {
    return this.responseReceived;
  }

  get chartDataQuality() {
    return this.chartDataFromAPIQuality;
  }

  get selectedSites() {
    const siteIds: any = [];
    if (this.selectedprogramSites.length) {
      this.selectedprogramSites.forEach((site: any) => {
        if (site.checked) {
          siteIds.push(site.id);
        }
      });
    }
    return siteIds;
  }

  get selectedSiteGroups() {
    return this.siteGroupsData;
  }

  get slectedProgram() {
    return this.selectedProgramData;
  }

  get programDropdownStatus() {
    return this.dropdownResponseStatus;
  }

  get doughNutData() {
    return this.doughnutDataFormat;
  }

  get isDataLoaded() {
    return this.dataloaded;
  }

  get centerTextData() {
    return this.centerTextDataObj;
  }

  get DoughNutEmptyState() {
    return this.isDoughNutEmptyState;
  }

  get applicationResponseStatus() {
    return this.getApplicationResponseStatus;
  }

  get applicationList() {
    return this.applicationSitesData;
  }
  get selectedApplications() {
    return this.applicationSelected;
  }

  get isDropdownDisable() {
    return this.dropdownDisabled;
  }

  get chartData() {
    return this.chartDataFromAPI;
  }
  get sitesAnalyticsDetails() {
    return this.selectedprogramSites.length ? this.sitesAnalyticsData : [];
  }

  get isAnalyticsDataReceived() {
    return this.isAnalyticsResponseReceived;
  }

  get barCompletionAnalyticalData() {
    const barData: any = [];
    if (this.sitesAnalyticsData && this.sitesAnalyticsData.dataSet && this.sitesAnalyticsData.dataSet.length) {
      const size: number = APP_CONST.SITE_LINE_BREAK_SIZE;
      let numChunks: number = 0;
      let chunks: any = [];
      this.sitesAnalyticsData.dataSet.forEach((data: any) => {
        let siteName: any = data.siteName;
        if (siteName && siteName.length > size) {
          numChunks = Math.ceil(siteName.length / size);
          chunks = new Array(numChunks);
          for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
            chunks[i] = siteName.substr(o, size);
          }
          siteName = chunks;
          if (siteName.length > 3) {
            siteName = [siteName[0], siteName[1], siteName[2].substr(0, size - 1) + '…'];
          }
        }
        barData.push({ data: ((Math.round(data.result) == APP_CONST.ZERO) ? 0.3 : ((Math.round(data.result) > APP_CONST.HUNDRED) ? APP_CONST.HUNDRED : Math.round(data.result))), site: siteName, tooltip: `${Math.round(data.result)}%` });
      });
    }
    return barData;
  }

  get barChartAnalyticsData() {
    const barData: any = [];
    if (this.sitesAnalyticsData.length) {
      const size: number = APP_CONST.SITE_LINE_BREAK_SIZE;
      let numChunks: number = 0;
      let chunks: any = [];
      let initInterval: number = 0;
      let finalInterval: number = 0;
      const maxYY = this.sitesAnalyticsData.map((element: any) => {
        return element.enrolledScholars;
      });
      const maxYValue = Math.max(...maxYY);
      initInterval = maxYValue + (10 - (maxYValue % 10));
      finalInterval = ((initInterval * 0.5) / 100);
      this.sitesAnalyticsData.forEach((data: any) => {
        let siteName: any = data.siteName;
        if (siteName.length > size) {
          numChunks = Math.ceil(siteName.length / size);
          chunks = new Array(numChunks);
          for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
            chunks[i] = siteName.substr(o, size);
          }
          siteName = chunks;
          if (siteName.length > 3) {
            siteName = [siteName[0], siteName[1], siteName[2].substr(0, size - 1) + '…'];
          }
        }

        barData.push({ data: ((data.enrolledScholars < finalInterval) ? finalInterval : data.enrolledScholars), site: siteName, tooltip: (data.scholarCapacity ? `${data.enrolledScholars}/${data.scholarCapacity}` : `${data.enrolledScholars}`), enrolledScholars: data.enrolledScholars });
      });
    }

    return barData;
  }

  get attendenceCompletionDetails() {
    return this.attendenceCompletionData;
  }

  get adaselectedSite() {
    return this.siteSelected;
  }

  get completionCsvDropdown() {
    return this.completionCSVlistOptions;
  }

  get disableDownloadCsvButtonCompletion() {
    return this.disableButtonCompletion;
  }

  get rolesList() {
    return this.profLearningRoles;
  }

  get categoriesList() {
    if (this.categories && (this.categories.length == APP_CONST.ONE)) {
      this.categories[0].checked = true;
    }
    return this.categories;
  }

  get selectedCategoryIds() {
    const categoryIds: any = [];
    if (this.categoriesList.length) {
      this.categoriesList.forEach((category: { id: number; checked: boolean; value: string }) => {
        if (category.checked) {
          categoryIds.push(category.id);
        }
      });
    }
    return categoryIds;
  }

  get selectedRoleIds() {
    const roleIds: any = [];
    if (this.profLearningRoles.length) {
      this.profLearningRoles.forEach((role: { id: number; checked: boolean }) => {
        if (role.checked) {
          roleIds.push(role.id);
        }
      });
    }
    return roleIds;
  }

  get prefLearningChartData() {
    return this.profLearningData;
  }

  get learningLegends() {
    const legends: any = [];
    const unCheckedList: any = [];
    if (this.rolesList && this.rolesList.length) {
      this.rolesList.forEach((role: any) => {
        if (role.checked) {
          legends.push({ id: role.id, name: role.value, color: role.color, count: role.count });
        } else {
          unCheckedList.push({ id: role.id, name: role.value, color: role.color, count: role.count });
        }
      });
    }
    return (this.rolesList.length != unCheckedList.length) ? legends : unCheckedList;
  }

  get retensionCsvDropdown() {
    return this.retensionCSVlistOptions;
  }

  get disableDownloadCsvButtonRetension() {
    return this.disableButtonRetension;
  }

  get getCompleteProfLearningData() {
    return this.completeProfLearningData;
  }

  @Mutation
  disableAdaProgramDropdown(disable: boolean) {
    this.disableAdaProgramdropdown = disable;
  }

  @Mutation
  completionBarChartLoaderVisible(val: boolean) {
    this.isCompletionBarChartLoaderVisible = val;
  }

  @Mutation
  mutateSelectedCategory(categories: any) {
    this.categories = categories;
  }

  @Mutation
  mutateSelectedRole(roles: any) {
    this.profLearningRoles = roles;
  }

  @Mutation
  filterProfessionalLearningData(filteredData: { roles: any; courses: any }) {
    const selectedLabels: string[] = [];
    this.profLearningData = { labels: [], datasets: [], totalCount: 0 };
    const filteredCourseIds: any[] = [];
    if (this.categories && this.categories.length) {
      const size: number = APP_CONST.SITE_LINE_BREAK_SIZE;
      let numChunks: number = 0;
      let chunks: any = [];
      this.categories.forEach((course: any) => {
        if (course.checked) {
          filteredCourseIds.push(course.id);
          let labelName: any = course.value;
          if (labelName.length > size) {
            numChunks = Math.ceil(labelName.length / size);
            chunks = new Array(numChunks);
            for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
              chunks[i] = labelName.substr(o, size);
            }
            labelName = chunks;
            if (labelName.length > 3) {
              labelName = [labelName[0], labelName[1], labelName[2].substr(0, size - 1) + '…'];
            }
          }
          selectedLabels.push(labelName);
        }
      });
      selectedLabels.push('');
    }
    if (filteredCourseIds && filteredCourseIds.length) {
      this.profLearningData.labels = selectedLabels;
      const datasets: any = [];
      this.profLearningData.totalCount = 0;
      if (this.completeProfLearningData && this.completeProfLearningData.datasets && this.completeProfLearningData.datasets.length) {
        this.completeProfLearningData.datasets.forEach((data: any) => {
          const dataValues: any = { data: [] };
          let roleIds: number[] = [];
          const unSelectedRoleIds: number[] = [];
          filteredData && filteredData.roles && filteredData.roles.forEach((role: { id: number; checked: boolean }) => {
            if (role.checked) {
              roleIds.push(role.id);
            } else {
              unSelectedRoleIds.push(role.id);
            }
          });
          if (unSelectedRoleIds && filteredData && filteredData.roles && (filteredData.roles.length == unSelectedRoleIds.length)) {
            roleIds = unSelectedRoleIds;
          }
          if (roleIds && roleIds.length && roleIds.includes(data.roleId)) {
            this.profLearningData.totalCount += data.userCount;
            if (data.hasOwnProperty('data') && data.data.length) {
              data.data.forEach((dataItem: any) => {
                if (filteredCourseIds.includes(dataItem.x)) {
                  dataValues.data.push(dataItem);
                }
              });
            }
          }

          datasets.push(
            {
              label: this.roleNames[data.roleId],
              backgroundColor: 'transparent',
              borderWidth: APP_CONST.TWO,
              lineTension: APP_CONST.ZERO,
              fill: APP_CONST.FALSE,
              pointRadius: 2.5,
              hoverRadius: APP_CONST.THREE,
              borderColor: this.roleColors[data.roleId],
              pointBackgroundColor: this.roleColors[data.roleId],
              data: dataValues.data,
            }
          );
        });
        this.profLearningData.datasets = datasets;
      }
    }
  }

  get disableDownloadCsvProfessionalLearningData() {
    return this.disableButtonProfessionalLearning;
  }

  get rolesCountList() {
    return this.roleCounts;
  }

  @Mutation
  resetProfessionalLearningData() {
    this.profLearningData = { labels: [], datasets: [], totalCount: 0 };
  }

  @Mutation
  clearCategories() {
    this.categories = [];
  }

  get enrollmentCsvDropdown() {
    return this.enrollmentCSVlistOptions;
  }

  get disableDownloadCsvButtonEnrollment() {
    return this.disableButtonEnrollment;
  }
  get disableDownloadConsolidatedScholarRoster() {
    return this.disableButtonConsolidatedRoster;
  }

  @Mutation
  mutateSitesAnalyticalData(response: any) {
    this.sitesAnalyticsData = response.data;
    if (this.sitesAnalyticsData && this.sitesAnalyticsData.hasOwnProperty('averagePercentage')) {
      this.completionProgramPercent = this.sitesAnalyticsData.averagePercentage ? this.sitesAnalyticsData.averagePercentage + '%' : '--';
    }
    if (this.sitesAnalyticsData && this.sitesAnalyticsData.hasOwnProperty('averagePercentage')) {
      this.disableButtonCompletion = this.sitesAnalyticsData.averagePercentage == APP_CONST.ZERO ? APP_CONST.TRUE : APP_CONST.FALSE;
    }
    let possibleChk = APP_CONST.ZERO;
    response.data.dataSet.length && response.data.dataSet.forEach((obj: any) => {
      possibleChk = possibleChk + obj.possible;
    });
    if (possibleChk == APP_CONST.ZERO) {
      this.completionProgramPercent = '--';
    }

  }

  @Mutation
  mutateSelectedSite(site?: any) {
    if (site) {
      this.siteSelected = site;
      this.selectedSite = site;
    } else {
      this.groupsList = [];
      this.selectedGroupIds = [];
      this.selectedSite = '';
      this.attendenceCompletionData = { datasets: [], labels: [] };
    }
  }

  @Mutation
  adaCsvEnableDisableItems() {
    this.listOptions = JSON.parse(JSON.stringify(this.listOptions));
    if (this.siteSelected && (this.siteSelected.id == 0)) {
      this.listOptions.options.forEach((option: any, optionIndex: number) => {
        if (optionIndex > APP_CONST.ZERO) {
          option.disable = true;
        }
      });
    } else {
      this.listOptions.options.forEach((option: any, optionIndex: number) => {
        if (optionIndex > APP_CONST.ZERO) {
          option.disable = false;
        }
      });
    }
    this.listOptions = JSON.parse(JSON.stringify(this.listOptions));
  }

  @Mutation
  completionCsvEnableDisableItems() {
    if ((this.selectedSite && (this.selectedSite.id == 0)) || (this.groupsList && (this.groupsList.length == 0))) {
      this.completionCSVlistOptions.options[1].disable = true;
      this.completionCSVlistOptions.options[2].disable = true;
    } else {
      this.completionCSVlistOptions.options[1].disable = false;
      this.completionCSVlistOptions.options[2].disable = false;
    }
  }

  @Mutation
  retensionCsvEnableDisableItems() {
    if ((this.siteSelected && (this.siteSelected.id == 0)) || (this.siteGroupsData && (this.siteGroupsData.length == 0))) {
      this.retensionCSVlistOptions.options[1].disable = true;
      this.retensionCSVlistOptions.options[2].disable = true;
    } else {
      this.retensionCSVlistOptions.options[1].disable = false;
      this.retensionCSVlistOptions.options[2].disable = false;
    }
  }

  @Mutation
  mutateSelectAllSite(isAllSelect: boolean) {
    this.isAllSelectSiteRequired = isAllSelect;
  }

  @Mutation
  public mutateApplicationStatus(status: { responseStatus: number }) {
    this.getApplicationResponseStatus = status;
  }

  @Mutation
  public mutateDropdownState(dropdownDisabled: boolean) {
    this.dropdownDisabled = dropdownDisabled;
  }

  @Mutation
  mutateDoughNutEmptyState(isEmpty: boolean) {
    this.isDoughNutEmptyState = isEmpty;
  }

  @Mutation
  mutateScholarNavigationSelection(tabKey: string) {
    this.selectedScholarNavigationtab = tabKey;
  }

  @Mutation
  mutateAnalyticsAttendanceTabSwitch(tabName: string) {
    this.selectedAttendanceAnalyticsTab = tabName;
  }

  @Mutation
  mutateAnalyticsSiteSessionTabSwitch(tabName: string) {
    this.selectedSiteSessionAnalyticsTab = tabName;
  }

  @Mutation
  mutateFamilyEngagementTab(tabName: string) {
    this.selectedFamilyEngagementTab = tabName;
  }

  @Mutation
  mutateAnalyticsProgramDropdown(dropdown: any) {
    const activePrograms: any = dropdown && dropdown.filter((program: any) => { return program.status == APP_CONST.ONE; });
    const disabledPrograms: any = dropdown && dropdown.filter((program: any) => { return program.status == APP_CONST.TWO; });
    this.programDropdownValue.allPrograms = dropdown;
    this.programDropdownValue.activePrograms = activePrograms;
    this.programDropdownValue.disabledPrograms = disabledPrograms;
  }

  @Mutation
  mutateSelectedProgramName(programData: any) {
    this.selectedProgramData = programData;
  }

  @Mutation
  resetCompletionData() {
    this.attendenceCompletionData = { datasets: [], labels: [] };
  }

  @Mutation
  resetSelectedProgramPerc() {
    this.completionProgramPercent = '--';
  }

  @Mutation
  mutateSelectedAttendenceProgramSites(response: any) {
    this.programSitesData = [];
    if (response && response.length) {
      this.programSitesData = [];
      this.selectedSite = '';
      response.forEach((session: any) => {
        if (!this.programSitesData.includes({ id: session.sessionId, value: session.sessionName })) {
          this.programSitesData.push({ id: session.sessionId, value: session.sessionName });
        }
      });
    }
  }

  @Mutation
  mutateSelectedRetentionProgramSites(response: any) {
    this.retentionProgramSitesData = [];
    if (response && response.length) {
      this.retentionProgramSitesData = [];
      response.forEach((session: any) => {
        if (!this.retentionProgramSitesData.includes({ id: session.sessionId, value: session.sessionName })) {
          this.retentionProgramSitesData.push({ id: session.sessionId, value: session.sessionName });
        }
      });
    }
  }

  @Mutation
  mutateQualityProgramSites(response: any) {
    this.programSitesData = [];
    if (response && response.length) {
      this.programSitesData = [];
      this.selectedSite = '';
      response.forEach((session: any) => {
        if (!this.programSitesData.includes({ id: session.sessionId, value: session.sessionName })) {
          this.programSitesData.push({ id: session.sessionId, value: session.sessionName });
        }
      });
    }
  }

  @Mutation
  async mutateSelectedProgramSites(response: { programId: number; getAnalyticsDetail?: boolean }) {
    const result = await programSites(response.programId);
    const siteIds: any = [];
    let enrollmentData: any = APP_UTILITIES.getFilteredColumns(`${this.authKey}-enrollmentSiteSessionData`);

    response.getAnalyticsDetail = !response.getAnalyticsDetail ? response.getAnalyticsDetail : true;

    this.isEnrollmentLoaderVisible = true;
    this.programSitesData = [];
    this.disableButtonEnrollment = true;
    this.disableButtonConsolidatedRoster = true;
    this.programSitesData = [];
    this.sitesAnalyticsData = [];
    this.attendenceCompletionData = { datasets: [], labels: [] };
    this.isAnalyticsResponseReceived = false;
    this.authKey = APP_UTILITIES.authTokenKeyToManage() ? APP_UTILITIES.authTokenKeyToManage() : '';

    if (enrollmentData && enrollmentData.length) {
      enrollmentData = JSON.parse(enrollmentData);
      this.enrollmentSettingObj = enrollmentData;
    }

    if (result.data && result.data.length) {
      if (APP_UTILITIES.getFilteredColumns(`${this.authKey}-enrollmentSiteSessionData`) && enrollmentData.hasOwnProperty([this.selectedProgramData.programId])) {
        let allSites: any[] = [];
        result.data.forEach((site: any) => {
          allSites = [...allSites, { id: site.sessionId, value: site.sessionName, checked: false }];
        });
        allSites.forEach((site: any) => {
          enrollmentData[this.selectedProgramData.programId].selectedSite.dropdownList.forEach((storedSite: any) => {
            if (site.id == storedSite.id) {
              site.checked = storedSite.checked;
            }
          });
        });
        this.programSitesData = allSites;
        let isNoSitesSelected: boolean = false;
        isNoSitesSelected = Object.keys(this.programSitesData).every((element: any) => !this.programSitesData[element].checked);

        if (isNoSitesSelected) {
          this.programSitesData.forEach((site: { id: any; checked: boolean }) => {
            siteIds.push(site.id);
          });
        } else {
          this.programSitesData.forEach((site: { id: any; checked: boolean }) => {
            if (site.checked) {
              siteIds.push(site.id);
            }
          });
        }
      } else {
        result.data.forEach((site: any) => {
          this.programSitesData = [...this.programSitesData, { id: site.sessionId, value: site.sessionName, checked: false }];
          siteIds.push(site.sessionId);
        });

        if (this.programSitesData && this.programSitesData.length == APP_CONST.ONE) {
          this.programSitesData[0].checked = APP_CONST.TRUE;
        }
      }

      if (response.getAnalyticsDetail) {
        if (siteIds.length) {
          const siteResultData = await programSitesAnalyticsDetail(response.programId, siteIds);
          this.sitesAnalyticsData = siteResultData.data;
          this.isEnrollmentLoaderVisible = false;

          if (this.sitesAnalyticsData && !this.sitesAnalyticsData.length) {
            this.disableButtonEnrollment = true;
            this.disableButtonConsolidatedRoster = true;
          } else {
            this.disableButtonEnrollment = false;
            this.disableButtonConsolidatedRoster = false;
          }

          this.isAnalyticsResponseReceived = true;
        }
      }
    }
  }


  @Mutation
  mutateGroupsList(group: any) {
    this.groupsList = group;
  }

  @Mutation
  mutateApplicationsList(result: any) {
    this.applicationSitesData = [];
    this.authKey = APP_UTILITIES.authTokenKeyToManage() ? APP_UTILITIES.authTokenKeyToManage() : '';
    if (result.data && result.data.length) {
      let applicationData: any = APP_UTILITIES.getFilteredColumns(`${this.authKey}-applicationapplicationname`);
      if (applicationData && applicationData.length) {
        applicationData = JSON.parse(applicationData);
        this.applicationObj = applicationData;
      }
      if (APP_UTILITIES.getFilteredColumns(`${this.authKey}-applicationapplicationname`) && applicationData.hasOwnProperty([this.selectedProgramData.programId])) {
        applicationSites(this.selectedProgramData.programId).then((response) => {
          let allApplications: any[] = [];
          if (response.data && response.data.length) {
            response.data.forEach((application: any) => {
              allApplications = [...allApplications, { id: application.applicationId, value: application.name, checked: false }];
            });
          }
          allApplications.forEach(application => {
            applicationData[this.selectedProgramData.programId].selectedApplication.forEach((stored: any) => {
              if (application.id == stored.id) {
                application.checked = stored.checked;
              }
            });
          });
          this.applicationSitesData = allApplications;
          let Applicationarray: any = [];
          this.applicationSitesData && this.applicationSitesData.forEach((element: any) => {
            if (element.checked) {
              Applicationarray = [...Applicationarray, element.id];
            }
          });
          this.applicationSelected = Applicationarray;
        });
      }
      else {
        this.applicationSelected = [];
        result.data.forEach((ele: any) => {
          this.applicationSitesData = [...this.applicationSitesData, { id: ele.applicationId, value: ele.name, checked: result.data.length == APP_CONST.ONE ? APP_CONST.TRUE : APP_CONST.FALSE }];
        });
      }
    }
    if (result && result.data && (!result.data.length || result.data.length == APP_CONST.ONE)) {
      this.dropdownDisabled = true;

    } else {
      this.dropdownDisabled = false;
    }
    this.responseReceived = true;
  }

  @Mutation
  mutateIsloaded(loaded: boolean) {
    this.dataloaded = loaded;
  }

  @Mutation
  mutateDropdownResponseStatus(status: number) {
    this.dropdownResponseStatus = status;
  }

  @Mutation
  mutateApplicationSelected(selectedApplication: any) {
    let selectedApplications: any = [];
    selectedApplication && selectedApplication.forEach((element: any) => {
      if (element.checked) {
        selectedApplications = [...selectedApplications, element.id];
      }
    });
    this.applicationSelected = selectedApplications;
  }

  @Mutation
  MutateQualityChartData(chartDataQuality: any) {
    this.chartDataFromAPIQuality = { labels: [], datasets: [] };
    if (chartDataQuality && chartDataQuality.data) {
      this.chartDataFromAPIQuality.labels = chartDataQuality.data.labels;
      this.chartDataFromAPIQuality.labels.push(['']);
      this.qualityPercentage = chartDataQuality.data && chartDataQuality.data.dataSet && chartDataQuality.data.dataSet[0].totalAverage ? Number(chartDataQuality.data.dataSet[0].totalAverage).toFixed(2) : '--';
      chartDataQuality && chartDataQuality.data.dataSet && chartDataQuality.data.dataSet.forEach((element: any) => {
        element.data.forEach((points: any) => {
          Object.keys(points).forEach(key => {
            points.y = points.y == 0 ? null : points.y;
          });
        });
        this.chartDataFromAPIQuality.datasets.push(
          {
            backgroundColor: 'transparent',
            borderWidth: APP_CONST.TWO,
            lineTension: APP_CONST.ZERO,
            fill: APP_CONST.FALSE,
            pointRadius: 2.5,
            hoverRadius: APP_CONST.THREE,
            borderColor: APP_CONST.STYLE.COLOR.BLUE_700,
            pointBackgroundColor: APP_CONST.STYLE.COLOR.BLUE_700,
            data: element.data,
          }
        );
      });
    }
  }

  @Mutation
  mutateChartData(chartDataFromAPI: any) {
    const chartData: any = { labels: [], datasets: [] };
    let currentIndex = this.colorsList.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = this.colorsList[currentIndex];
      this.colorsList[currentIndex] = this.colorsList[randomIndex];
      this.colorsList[randomIndex] = temporaryValue;
    }
    let indexColor = 0;
    if (chartDataFromAPI && chartDataFromAPI.data) {
      chartData.labels = chartDataFromAPI.data && chartDataFromAPI.data.labels;
      chartData.labels = [...chartData.labels, ''];
      chartDataFromAPI && chartDataFromAPI.data.dataSet && chartDataFromAPI.data.dataSet.forEach((element: any) => {
        chartData.datasets.push(
          {
            label: element.applicationName,
            backgroundColor: 'transparent',
            borderWidth: APP_CONST.TWO,
            lineTension: APP_CONST.ZERO,
            fill: APP_CONST.FALSE,
            pointRadius: 2.5,
            hoverRadius: APP_CONST.THREE,
            borderColor: this.colorsList[indexColor],
            pointBackgroundColor: this.colorsList[indexColor],
            data: element.data,
            status: element.status
          }
        );
        indexColor++;
        if (indexColor == (this.colorsList.length + 1)) {
          indexColor = APP_CONST.ZERO;
        }
      });
    }
    this.chartDataFromAPI = chartData;
  }

  @Mutation
  emptyChartData() {
    this.chartDataFromAPI = { labels: [], datasets: [] };
  }

  @Mutation
  mutateAttendanceSiteSession(data: { programId: any; site: any; groups: any }) {
    const programId = data.programId;
    const siteId: any = data.site;
    if (!Object.keys(this.adaSettingObj).length) {
      this.adaSettingObj = {
        [programId]: {
          selectedSite: data.site,
          selectedGroups: data.groups
        }
      };
    }
    this.authKey = APP_UTILITIES.authTokenKeyToManage() ? APP_UTILITIES.authTokenKeyToManage() : '';
    if (this.adaSettingObj.hasOwnProperty([programId])) {
      this.adaSettingObj[programId] = {
        selectedSite: data.site,
        selectedGroups: data.groups
      };
    }
    else if (!this.adaSettingObj.hasOwnProperty([programId])) {
      const programData: any = {
        [programId]: {
          selectedSite: data.site,
          selectedGroups: data.groups
        }
      };
      Object.assign(this.adaSettingObj, JSON.parse(JSON.stringify(programData)));
    }
    APP_UTILITIES.setFilteredColumns(`${this.authKey}-attendanceSiteSessionData`, this.adaSettingObj);
  }

  @Mutation
  mutateRetentionSiteSession(data: { programId: any; site: any; groups: any }) {
    const programId = data.programId;
    if (!Object.keys(this.retentionSetitngsObj).length) {
      this.retentionSetitngsObj = {
        [programId]: {
          selectedSite: data.site,
          selectedGroups: data.groups
        }
      };
    }
    this.authKey = APP_UTILITIES.authTokenKeyToManage() ? APP_UTILITIES.authTokenKeyToManage() : '';
    if (this.retentionSetitngsObj.hasOwnProperty([programId])) {
      this.retentionSetitngsObj[programId] = {
        selectedSite: data.site,
        selectedGroups: data.groups
      };
    }
    else if (!this.retentionSetitngsObj.hasOwnProperty([programId])) {
      const programData: any = {
        [programId]: {
          selectedSite: data.site,
          selectedGroups: data.groups
        }
      };
      Object.assign(this.retentionSetitngsObj, JSON.parse(JSON.stringify(programData)));
    }
    APP_UTILITIES.setFilteredColumns(`${this.authKey}-retentionSiteSessionData`, this.retentionSetitngsObj);
  }

  @Mutation
  mutateadaSettingObj(settingsObj: any) {
    this.adaSettingObj = settingsObj;
  }

  @Mutation
  mutateRetentionSettingObj(settingsObj: any) {
    this.retentionSetitngsObj = settingsObj;
  }

  @Action
  async getPersistenceScholarTab(data: { selectedTab: string; programId: number }): Promise<void> {
    const highestRole: number = JSON.parse(JSON.stringify(APP_UTILITIES.getCookie('highest_role')));
    const authKey = APP_UTILITIES.authTokenKeyToManage() ? APP_UTILITIES.authTokenKeyToManage() : '';
    if (data.selectedTab == 'attendanceAda') {
      if (APP_UTILITIES.getFilteredColumns(`${authKey}-attendanceSiteSessionData`)) {
        let attendanceAdaData: any = APP_UTILITIES.getFilteredColumns(`${authKey}-attendanceSiteSessionData`);
        attendanceAdaData = JSON.parse(attendanceAdaData);
        this.mutateadaSettingObj(attendanceAdaData);
        if (attendanceAdaData.hasOwnProperty([data.programId])) {
          const siteResponse = await programSites(data.programId);
          const siteIds: any[] = [];
          siteResponse.data.forEach((site: any) => { siteIds.push(site.siteId); });
          if (siteIds.includes(attendanceAdaData[data.programId].selectedSite.id)) {
            await getGroupsBySiteid({ programId: data.programId, siteId: attendanceAdaData[data.programId].selectedSite.id }).then((response) => {
              let allGroups: any[] = [];
              if (response.data && response.data.length) {
                if (highestRole == APP_CONST.SEVEN) {
                  response.data.forEach((group: any) => {
                    if (group.myGroup) {
                      allGroups = [...allGroups, { id: group.id, value: group.classroomName, checked: false }];
                    }
                  });
                } else {
                  response.data.forEach((group: any) => {
                    allGroups = [...allGroups, { id: group.id, value: group.classroomName, checked: false }];
                  });
                }
              }

              allGroups.forEach(group => {
                attendanceAdaData[data.programId].selectedGroups.forEach((stored: any) => {
                  if (group.id == stored.id) {
                    group.checked = stored.checked;
                  }
                });
              });

              this.mutateSelectedSite(attendanceAdaData[data.programId].selectedSite);
              this.mutateSelectedGroups(allGroups);
              this.adaCsvEnableDisableItems();
            });
          } else {
            this.mutateSelectedSite({ id: 0, value: 'Select Session' });
            this.mutateSelectedGroups([]);
            this.adaCsvEnableDisableItems();
          }
        }
        else {
          this.mutateSelectedSite({ id: 0, value: 'Select Session' });
          this.adaCsvEnableDisableItems();
        }
      }
    }
    else if (data.selectedTab == 'retention') {
      if (APP_UTILITIES.getFilteredColumns(`${authKey}-retentionSiteSessionData`)) {
        let retentionData: any = APP_UTILITIES.getFilteredColumns(`${authKey}-retentionSiteSessionData`);
        retentionData = JSON.parse(retentionData);
        this.mutateRetentionSettingObj(retentionData);
        if (retentionData.hasOwnProperty([data.programId])) {
          await getGroupsBySiteid({ programId: data.programId, siteId: retentionData[data.programId].selectedSite.id }).then((response) => {
            let allGroups: any[] = [];
            if (response.data && response.data.length) {
              if (highestRole == APP_CONST.SEVEN) {
                response.data.forEach((group: any) => {
                  if (group.myGroup) {
                    allGroups = [...allGroups, { id: group.id, value: group.classroomName, checked: false }];
                  }
                });
              } else {
                response.data.forEach((group: any) => {
                  allGroups = [...allGroups, { id: group.id, value: group.classroomName, checked: false }];
                });
              }
            }

            allGroups.forEach(group => {
              retentionData[data.programId].selectedGroups.forEach((stored: any) => {
                if (group.id == stored.id) {
                  group.checked = stored.checked;
                }
              });
            });
            this.mutateSelectedSite(retentionData[data.programId].selectedSite);
            this.mutateSelectedGroups(allGroups);
            this.retensionCsvEnableDisableItems();
          });
        }
        else {
          this.mutateSelectedSite({ id: 0, value: 'Select Session' });
          this.retensionCsvEnableDisableItems();
        }
      }
    }
    else if (data.selectedTab == 'completion') {
      if (APP_UTILITIES.getFilteredColumns(`${authKey}-attendanceSiteSessionData`)) {
        let attendanceAdaData: any = APP_UTILITIES.getFilteredColumns(`${authKey}-attendanceSiteSessionData`);
        attendanceAdaData = JSON.parse(attendanceAdaData);
        this.mutateadaSettingObj(attendanceAdaData);
        if (attendanceAdaData.hasOwnProperty([data.programId])) {
          let sites: any[] = [];
          const siteResponse = await programSites(data.programId);
          sites = siteResponse.data;
          const siteIds: any[] = [];
          sites.forEach((site: any) => {
            siteIds.push(site.siteId);
          });
          if (siteIds.includes(attendanceAdaData[data.programId].selectedSite.id)) {
            await getGroupsBySiteid({ programId: data.programId, siteId: attendanceAdaData[data.programId].selectedSite.id }).then((response) => {
              let allGroups: any[] = [];
              if (response.data && response.data.length) {
                if (highestRole == APP_CONST.SEVEN) {
                  response.data.forEach((group: any) => {
                    if (group.myGroup) {
                      allGroups = [...allGroups, { id: group.id, value: group.classroomName, checked: false }];
                    }
                  });
                } else {
                  response.data.forEach((group: any) => {
                    allGroups = [...allGroups, { id: group.id, value: group.classroomName, checked: false }];
                  });
                }
              }

              allGroups.forEach(group => {
                attendanceAdaData[data.programId].selectedGroups.forEach((stored: any) => {
                  if (group.id == stored.id) {
                    group.checked = stored.checked;
                  }
                });
              });

              this.mutateSelectedSite({ ...attendanceAdaData[data.programId].selectedSite, programId: data.programId });
              this.mutateGroupsList(allGroups);
              this.completionCsvEnableDisableItems();
            });
          } else {
            this.mutateSelectedSite({ id: 0, value: 'Select Session' });
            this.mutateGroupsList([]);
            this.completionCsvEnableDisableItems();
          }

        }
        else {
          this.mutateSelectedSite({ id: 0, value: 'Select Session' });
          this.completionCsvEnableDisableItems();
        }
      }
    }
  }

  @Action({ commit: 'mutateChartData' })
  async renderChart(applicationSelected: Array<string>) {
    this.emptyChartData();
    let applicationSelectedByUser: string[] = [];
    let result: any = {};
    if (this.applicationSitesData && this.applicationSitesData.length && applicationSelected && !applicationSelected.length) {
      result = await applicationAnalyticsDetail(this.selectedProgramData.programId, APP_CONST.BLANK);
    } else if (applicationSelected && applicationSelected.length) {
      applicationSelectedByUser = applicationSelected;
      result = await applicationAnalyticsDetail(this.selectedProgramData.programId, applicationSelectedByUser);
    }
    return result;
  }

  @Action({ commit: 'MutateQualityChartData' })
  async renderQualityChart(siteSelected: { id: number; value: string }) {
    this.emptyQualityChartData();
    let selectedSite: any = {};
    let result: any = {};
    if (!siteSelected) {
      selectedSite = { id: 0, value: 'All Sites' };
    } else {
      selectedSite = siteSelected;
    }
    result = await qualityAnalyticsDetail(this.selectedProgramData.programId, selectedSite.id);
    return result;
  }

  @Mutation
  mutateSitesAnalyticsResponse(sitesResponse: any) {
    this.isEnrollmentLoaderVisible = false;
    this.sitesAnalyticsData = sitesResponse;
    this.isAnalyticsResponseReceived = true;
  }
  @Mutation
  mutateSelectedGroupIds(groupIds: any) {
    this.selectedGroupIds = groupIds;
  }

  @Mutation
  mutateSiteGroupsAnalyticsList(completionRes: any) {
    const chartData: any = { labels: [], datasets: [] };
    this.isCompletionLineChartVisible = true;
    let currentIndex = this.colorsList.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = this.colorsList[currentIndex];
      this.colorsList[currentIndex] = this.colorsList[randomIndex];
      this.colorsList[randomIndex] = temporaryValue;
    }
    let indexColor = 0;
    chartData.labels = completionRes.data && completionRes.data.labels;
    chartData.labels = [...chartData.labels, ''];
    const highest_role: any = APP_UTILITIES.getCookie(APP_CONST.HIGHEST_ROLE);
    if ((highest_role == APP_CONST.ROLE_TYPE_STAFF) && !this.selectedGroupIds.length) {
      chartData.datasets.push({ data: [] });
    } else {
      completionRes && completionRes.data.dataSet && completionRes.data.dataSet.forEach((element: any) => {
        element.data.forEach((points: any) => {
          Object.keys(points).forEach(key => {
            points.y = points.y == APP_CONST.MINUS_ONE ? null : points.y;
          });
        });
        chartData.datasets.push({
          backgroundColor: 'transparent',
          borderWidth: APP_CONST.TWO,
          lineTension: APP_CONST.ZERO,
          fill: APP_CONST.FALSE,
          pointRadius: 2.5,
          hoverRadius: APP_CONST.THREE,
          borderColor: this.selectedGroupIds && this.selectedGroupIds.length ? this.colorsList[indexColor] : APP_CONST.STYLE.COLOR.BLUE_700,
          pointBackgroundColor: this.selectedGroupIds && this.selectedGroupIds.length ? this.colorsList[indexColor] : APP_CONST.STYLE.COLOR.BLUE_700,
          data: element.data,
          status: element.status,
          groupId: element.groupId,
        });
        indexColor++;
        if (indexColor == (this.colorsList.length + 1)) {
          indexColor = APP_CONST.ZERO;
        }
      });
    }
    this.attendenceCompletionData = chartData;
  }

  @Mutation
  async mutateSiteGroupsList(groupsList: any) {
    const groups: any = [];
    const groupIds: any = [];
    let currentIndex = this.colorsList.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = this.colorsList[currentIndex];
      this.colorsList[currentIndex] = this.colorsList[randomIndex];
      this.colorsList[randomIndex] = temporaryValue;
    }
    let indexColor = 0;
    if (groupsList.data && groupsList.data.length && (groupsList.status && (groupsList.status == APP_CONST.RESPONSE_200))) {
      const highest_role: any = APP_UTILITIES.getCookie(APP_CONST.HIGHEST_ROLE);
      let currentGroup: any = '';
      groupsList.data.forEach((group: any) => {
        if (highest_role == APP_CONST.ROLE_TYPE_STAFF && group.myGroup) {
          currentGroup = { id: group.id, value: group.classroomName, checked: true };
          if (!groupIds.includes(group.id)) {
            groupIds.push(group.id);
          }
        } else if (highest_role != APP_CONST.ROLE_TYPE_STAFF) {
          currentGroup = { id: group.id, value: group.classroomName };
        }

        if (!groups.includes(currentGroup) && currentGroup) {
          groups.push(currentGroup);
        }

      });
      this.selectedGroupIds = groupIds;
    }
    this.groupsList = groups;

    if (this.selectedSite && this.selectedSite.id >= 0 && this.selectedSite.programId) {
      const completionRes = await completionSiteAnalyticsDetail(this.selectedSite.programId, this.selectedSite.id, groupIds, this.selectedSiteSessionAnalyticsTab);
      this.isCompletionLineChartVisible = true;
      const chartData: any = { labels: [], datasets: [] };


      chartData.labels = completionRes.data && completionRes.data.labels;
      chartData.labels = [...chartData.labels, ''];
      completionRes && completionRes.data.dataSet && completionRes.data.dataSet.forEach((element: any) => {
        element.data.forEach((points: any) => {
          Object.keys(points).forEach(key => {
            points.y = points.y == APP_CONST.MINUS_ONE ? null : points.y;
          });
        });
        chartData.datasets.push({
          backgroundColor: 'transparent',
          borderWidth: APP_CONST.TWO,
          lineTension: APP_CONST.ZERO,
          fill: APP_CONST.FALSE,
          pointRadius: 2.5,
          hoverRadius: APP_CONST.THREE,
          borderColor: this.selectedGroupIds && this.selectedGroupIds.length ? this.colorsList[indexColor] : APP_CONST.STYLE.COLOR.BLUE_700,
          pointBackgroundColor: this.selectedGroupIds && this.selectedGroupIds.length ? this.colorsList[indexColor] : APP_CONST.STYLE.COLOR.BLUE_700,
          data: element.data,
          status: element.status,
          groupId: element.groupId
        });
        indexColor++;
        if (indexColor == (this.colorsList.length + 1)) {
          indexColor = APP_CONST.ZERO;
        }
      });
      this.attendenceCompletionData = chartData;
    }
  }

  @Mutation
  completionLineChartVisible(visible: boolean) {
    this.isCompletionLineChartVisible = visible;
  }

  @Mutation
  mutateRetentionProgramSites(sites: any) {
    this.retentionProgramSitesData = sites;
  }


  @Action({ commit: 'mutateSelectedProgramSites' })
  async getProgramSites(selectedProgramId: number) {
    return { programId: selectedProgramId, getAnalyticsDetail: true };
  }

  @Mutation
  mutateCompletionProgramSites(sites: any) {
    this.programSitesData = sites;
  }

  @Action({ commit: 'mutateSelectedAttendenceProgramSites' })
  async getProgramAttendenceCompletionSites(selectedProgramId: number) {
    this.mutateCompletionProgramSites([]);
    const result = await programSites(selectedProgramId);
    if (result.status == APP_CONST.RESPONSE_204) {
      this.mutateSelectedSite('');
      this.mutateCompletionProgramSites([]);
    }
    return result.data;
  }

  @Action({ commit: 'mutateSelectedRetentionProgramSites' })
  async getProgramRetentionCompletionSites(selectedProgramId: number) {
    this.mutateRetentionProgramSites([]);
    const result = await programSites(selectedProgramId);
    if (result.status == APP_CONST.RESPONSE_204) {
      this.mutateSelectedSite('');
      this.mutateRetentionProgramSites([]);
    }
    return result.data;
  }

  @Action({ commit: 'mutateQualityProgramSites' })
  async getProgramSitesQuality(selectedProgramId: number) {
    this.mutateRetentionProgramSites([]);
    const result = await programSites(selectedProgramId);
    if (result.status == APP_CONST.RESPONSE_204) {
      this.mutateSelectedSite('');
      this.mutateRetentionProgramSites([]);
    }
    return result.data;
  }



  @Action({ commit: 'mutateSitesAnalyticalData' })
  async getProgramAttendenceCompletionBarSites(selectedProgramId: number) {
    this.completionFileDownloadtrue();
    this.setcompletionProgramPercentBlank();
    const completionAnalyticalData = await getAttenCompletionProgramData(selectedProgramId);
    return completionAnalyticalData;
  }

  @Action({ commit: 'mutateApplicationsList' })
  async getApplicationSites(selectedProgramId: number) {
    const result = await applicationSites(selectedProgramId);
    return result;
  }

  @Action({ commit: 'mutateSelectedProgramName' })
  programNameSelection(selectedProgramId?: any) {
    let programId: string | number | null;
    if (APP_UTILITIES.getCookie('programId') && selectedProgramId == undefined) {
      programId = APP_UTILITIES.getCookie('programId');
      programId = Number(programId);
    } else if (selectedProgramId) {
      programId = selectedProgramId;
    }

    const filteredProgramData = this.programDropdownValue.allPrograms.find((programs: any) => programs.programId == programId);

    return filteredProgramData;
  }

  @Mutation
  mutateApplicationResponse(response: any) {
    this.getApplicationResponseStatus.responseStatus = response.status;
    this.doughnutDataFormat = {
      Declined: 0,
      Waitlist: 0,
      Pending: 0
    };
    this.centerTextDataObj = {
      name: 0,
      value: APP_CONST.TOTAL_SUBMITTED
    };
    if (response.status == APP_CONST.RESPONSE_200) {
      this.isDoughNutEmptyState = false;
      response && response.data && response.data.enrollmentCountByStatus.forEach((element: any) => {
        switch (element.enrollmentStatus) {
          case APP_CONST.REJECT:
            this.doughnutDataFormat.Declined = element && element.countByStatus;
            break;
          case APP_CONST.WAITLIST:
            this.doughnutDataFormat.Waitlist = element && element.countByStatus;
            break;
          case APP_CONST.PENDING:
            this.doughnutDataFormat.Pending = element && element.countByStatus;
            break;
          default:
            break;
        }

      });
      this.centerTextDataObj = { name: response && response.data && response.data.totalSubmissionsCount, value: APP_CONST.TOTAL_SUBMITTED };
      if ((response.data.totalSubmissionsCount == 0) || ((!this.doughnutDataFormat.Declined && !this.doughnutDataFormat.Pending && !this.doughnutDataFormat.Waitlist))) {
        this.isDoughNutEmptyState = true;
      }
    } else if (response.status == APP_CONST.RESPONSE_204) {
      this.isDoughNutEmptyState = true;
    }
    else {
      this.isDoughNutEmptyState = true;
    }

  }

  @Action({ commit: 'mutateApplicationResponse' })
  async fetchApplicationData(payload: IApplicationPayload) {
    let applicationSelectedByUser: string[] = [];
    let response: any = {};
    if (payload.applicationIds && !payload.applicationIds.length) {
      response = await getApplicationData(payload.program, APP_CONST.BLANK);
    } else if (payload.applicationIds && payload.applicationIds.length) {
      applicationSelectedByUser = payload.applicationIds;
      response = await getApplicationData(this.selectedProgramData.programId, applicationSelectedByUser);
    }
    return response;
  }

  @Mutation
  mutateFamilyResponse(response: any) {
    this.familyEnagementFamilyData = {
      activeMobileUsers: 0,
      totalActiveAccounts: 0,
      totalDirectMessages: 0,
      totalLikedPosts: 0
    };
    if (response && response.status == APP_CONST.RESPONSE_200) {
      this.familyEnagementFamilyData.activeMobileUsers = response && response.data && response.data.activeMobileUsers;
      this.familyEnagementFamilyData.totalActiveAccounts = response && response.data && response.data.totalActiveAccounts;
      this.familyEnagementFamilyData.totalDirectMessages = response && response.data && response.data.totalDirectMessages;
      this.familyEnagementFamilyData.totalLikedPosts = response && response.data && response.data.totalLikedPosts;

    }
  }

  @Action({ commit: 'mutateFamilyResponse' })
  async fetchFamilyEnagementFamilyData(accountId: number) {
    const response = await getFamilyEngagementFamilyData(accountId);
    return response;
  }

  @Mutation
  mutateTeamResponse(response: any) {
    this.familyEnagementTeamData = {
      totalActiveMobileUsers: 0,
      totalMessagesToGuardians: 0,
      totalMessagesToTeamMembers: 0,
      totalPosts: 0
    };
    if (response && response.status == APP_CONST.RESPONSE_200) {
      this.familyEnagementTeamData.totalPosts = response && response.data && response.data.totalPosts;
      this.familyEnagementTeamData.totalActiveMobileUsers = response && response.data && response.data.totalActiveMobileUsers;
      this.familyEnagementTeamData.totalMessagesToTeamMembers = response && response.data && response.data.totalMessagesToTeamMembers;
      this.familyEnagementTeamData.totalMessagesToGuardians = response && response.data && response.data.totalMessagesToGuardians;

    }
  }

  @Mutation
  mutateAdaBarData(response: any) {
    this.disableButton = false;
    this.adaBarData = [];
    this.adaProgramPercent = '--';
    if (response && response.data && !response.data.dataSet) {
      this.disableButton = true;
    }
    if (response && response.data && response.data.dataSet && response.data.dataSet.length) {
      let isNoAttendance: boolean = false;
      isNoAttendance = Object.keys(response.data.dataSet).every((element: any) => !response.data.dataSet[element].isAttendanceMarked);
      this.adaProgramPercent = isNoAttendance ? '--' : Number(response.data.averagePercentage).toFixed(2) + '%';
      if (isNoAttendance) {
        this.disableButton = true;
      } else {
        this.disableButton = false;
      }
      const size: number = APP_CONST.SITE_LINE_BREAK_SIZE;
      let numChunks: number = 0;
      let chunks: any = [];
      response.data.dataSet.forEach((data: any) => {
        let siteName: any = data.siteName;
        if (siteName.length > size) {
          numChunks = Math.ceil(siteName.length / size);
          chunks = new Array(numChunks);
          for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
            chunks[i] = siteName.substr(o, size);
          }
          siteName = chunks;
          if (siteName.length > 3) {
            siteName = [siteName[0], siteName[1], siteName[2].substr(0, size - 1) + '…'];
          }
        }
        this.adaBarData.push({ data: !data.isAttendanceMarked ? 0 : data.result == 0 ? APP_CONST.ZERO_BAR_HEIGHT : data.result, site: siteName, positive: data.positive, tooltip: `${Math.round(data.result)}% ` });
      });
    }
  }

  @Mutation
  async mutateRetentionBarData(response: any) {
    this.retentionBarData = [];
    this.retentionProgramPercent = '--';
    if (response && response.data && !response.data.dataSet) {
      this.disableButtonRetension = true;
    }
    if (response && response.data && response.data.dataSet && response.data.dataSet.length) {
      let isNoAttendance: boolean = false;
      isNoAttendance = Object.keys(response.data.dataSet).every((element: any) => !response.data.dataSet[element].isAttendanceMarked);
      this.retentionProgramPercent = isNoAttendance ? '--' : Number(response.data.averagePercentage).toFixed(2) + '%';
      if (isNoAttendance) {
        this.disableButtonRetension = true;
      } else {
        this.disableButtonRetension = false;
      }
      const size: number = APP_CONST.SITE_LINE_BREAK_SIZE;
      let numChunks: number = 0;
      let chunks: any = [];

      const siteResponse = await programSites(this.selectedProgramData.programId);
      const siteMap = new Map();

      if (siteResponse && siteResponse.data.length) {
        siteResponse.data.forEach((siteDetail: any) => siteMap.set(siteDetail.sessionId, siteDetail.sessionName));
      }

      response.data.dataSet.forEach((data: any) => {
        let siteName: any = data.siteName || siteMap.get(data.siteId);
        if (siteName && siteName.length > size) {
          numChunks = Math.ceil(siteName.length / size);
          chunks = new Array(numChunks);
          for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
            chunks[i] = siteName.substr(o, size);
          }
          siteName = chunks;
          if (siteName.length > 3) {
            siteName = [siteName[0], siteName[1], siteName[2].substr(0, size - 1) + '…'];
          }
        }
        this.retentionBarData.push({ data: !data.isAttendanceMarked ? 0 : data.result == 0 ? APP_CONST.ZERO_BAR_HEIGHT : data.result, site: siteName, positive: data.positive, tooltip: `${Math.round(data.result)}% ` });
      });
    }
  }

  @Action({ commit: 'mutateTeamResponse' })
  async fetchFamilyEnagementTeamData(accountId: number) {
    const response = await getFamilyEngagementTeamData(accountId);
    return response;
  }


  @Mutation
  mutateGroups(response: any) {
    this.siteGroupsData = [];
    const highestRole: number = JSON.parse(JSON.stringify(APP_UTILITIES.getCookie('highest_role')));
    if (response.data && response.data.length) {
      if (highestRole == APP_CONST.SEVEN) {
        response.data.forEach((group: any) => {
          if (group.myGroup) {
            this.siteGroupsData = [...this.siteGroupsData, { id: group.id, value: group.classroomName, checked: true }];
          }
        });
      } else {
        response.data.forEach((group: any) => {
          this.siteGroupsData = [...this.siteGroupsData, { id: group.id, value: group.classroomName, checked: false }];
        });
      }
    }
  }

  @Mutation
  mutateSelectedGroups(groups: any) {
    this.siteGroupsData = groups;
  }

  @Action({ commit: 'mutateGroups' })
  getGroupsBySiteid(payload: IGroupsBySiteid) {
    const response = getGroupsBySiteid(payload);
    return response;
  }

  @Mutation
  mutateAdaWeekGraphDAta(response: any) {
    this.adaWeekGraphDAta = { labels: [], datasets: [] };
    const chartData = response.data;
    this.adaWeekGraphDAta.labels = chartData.labels;
    this.adaWeekGraphDAta.labels.push(['']);
    if (chartData && chartData.dataSet && chartData.dataSet.length == 1 && chartData.dataSet[0].data.length == 0) {
      this.listOptions.options.forEach((option: any, optionIndex: number) => {
        if (optionIndex > APP_CONST.ZERO) {
          option.disable = true;
        }
      });
    } else {
      if (this.siteSelected && (this.siteSelected.id == 0)) {
        this.listOptions.options.forEach((option: any, optionIndex: number) => {
          if (optionIndex > APP_CONST.ZERO) {
            option.disable = true;
          }
        });
      } else {
        this.listOptions.options.forEach((option: any, optionIndex: number) => {
          if (optionIndex > APP_CONST.ZERO) {
            option.disable = false;
          }
        });
      }
    }

    let indexColor = 0;
    let currentIndex = this.colorsList.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = this.colorsList[currentIndex];
      this.colorsList[currentIndex] = this.colorsList[randomIndex];
      this.colorsList[randomIndex] = temporaryValue;
    }

    chartData && chartData.dataSet && chartData.dataSet.forEach((element: any, index: any) => {
      const labelName = this.siteGroupsData && this.siteGroupsData.filter((grp: any) => grp.id == element.groupId)[0];
      element.data.forEach((points: any) => {
        Object.keys(points).forEach(key => {
          points.y = points.y == APP_CONST.MINUS_ONE ? null : points.y;
        });
      });
      this.adaWeekGraphDAta.datasets.push(
        {
          label: labelName && labelName.value,
          backgroundColor: 'transparent',
          borderWidth: APP_CONST.TWO,
          lineTension: APP_CONST.ZERO,
          fill: APP_CONST.FALSE,
          pointRadius: 2.5,
          hoverRadius: APP_CONST.THREE,
          borderColor: labelName && labelName.value ? this.colorsList[indexColor] : APP_CONST.STYLE.COLOR.BLUE_700,
          pointBackgroundColor: labelName && labelName.value ? this.colorsList[indexColor] : APP_CONST.STYLE.COLOR.BLUE_700,
          data: element.data,
        }
      );

      indexColor++;
      if (indexColor == (this.colorsList.length + 1)) {
        indexColor = APP_CONST.ZERO;
      }

    });

  }

  @Mutation
  emptyAdaWeekChartData() {
    this.adaWeekGraphDAta = { labels: [], datasets: [] };
  }

  @Mutation
  emptyRetentionChartData() {
    this.retentionGraphData = { labels: [], datasets: [] };
  }

  @Mutation
  emptyQualityChartData() {
    this.chartDataFromAPIQuality = { labels: [], datasets: [] };
  }

  @Mutation
  mutateRetentionGraphData(response: any) {
    this.retentionGraphData = { labels: [], datasets: [] };
    const chartData = response.data;
    this.retentionGraphData.labels = chartData.labels;
    this.retentionGraphData.labels.push(['']);
    let indexColor = 0;
    let currentIndex = this.colorsList.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = this.colorsList[currentIndex];
      this.colorsList[currentIndex] = this.colorsList[randomIndex];
      this.colorsList[randomIndex] = temporaryValue;
    }

    chartData && chartData.dataSet && chartData.dataSet.forEach((element: any, index: any) => {
      const labelName = this.siteGroupsData && this.siteGroupsData.filter((grp: any) => grp.id == element.groupId)[0];
      this.retentionGraphData.datasets.push(
        {
          label: labelName && labelName.value,
          backgroundColor: 'transparent',
          borderWidth: APP_CONST.TWO,
          lineTension: APP_CONST.ZERO,
          fill: APP_CONST.FALSE,
          pointRadius: 2.5,
          hoverRadius: APP_CONST.THREE,
          borderColor: labelName && labelName.value ? this.colorsList[indexColor] : APP_CONST.STYLE.COLOR.BLUE_700,
          pointBackgroundColor: labelName && labelName.value ? this.colorsList[indexColor] : APP_CONST.STYLE.COLOR.BLUE_700,
          data: element.data,
        }
      );

      indexColor++;
      if (indexColor == (this.colorsList.length + 1)) {
        indexColor = APP_CONST.ZERO;
      }
    });

  }

  @Mutation
  fileDownloadtrue() {
    this.disableButton = true;
  }

  @Mutation
  fileDownloadfalse() {
    this.disableButton = false;
  }

  @Mutation
  completionFileDownloadtrue() {
    this.disableButtonCompletion = true;
  }

  @Mutation
  completionFileDownloadfalse() {
    this.disableButtonCompletion = false;
  }

  @Mutation
  retensionFileDownloadtrue() {
    this.disableButtonRetension = true;
  }

  @Mutation
  retensionFileDownloadfalse() {
    this.disableButtonRetension = false;
  }

  @Mutation
  enrollmentFileDownloadtrue() {
    this.disableButtonEnrollment = true;
  }
  @Mutation
  consolidatedScholarRosterDownloadtrue() {
    this.disableButtonConsolidatedRoster = true;
  }
  @Mutation
  professionalLearningFileDownloadfalse() {
    this.disableButtonProfessionalLearning = false;
  }

  @Mutation
  professionalLearningFileDownloadtrue() {
    this.disableButtonProfessionalLearning = true;
  }

  @Mutation
  enrollmentFileDownloadfalse() {
    this.disableButtonEnrollment = false;
  }
  @Mutation
  consolidatedScholarRosterDownloadfalse() {
    this.disableButtonConsolidatedRoster = false;
  }
  @Action
  getAdaWeekLineData(params: IAdaWeekLinePayload) {
    this.emptyAdaWeekChartData();
    const response = getAdaWeekLineData(params);
    return response;
  }

  @Action
  getRetentionLineData(payload: IAdaWeekLinePayload) {
    this.emptyRetentionChartData();
    const response = getRetentionWeekLineData(payload);
    return response;
  }

  @Mutation
  setEnrollmentLoaderState() {
    this.isEnrollmentLoaderVisible = true;
  }

  @Action({ commit: 'mutateSitesAnalyticsResponse' })
  async fetchSitesAnalyticsData(programId: number) {
    this.setEnrollmentLoaderState();
    const response = await programSitesAnalyticsDetail(programId, this.selectedSites);
    return response.data;
  }

  @Action({ commit: 'mutateSiteGroupsList' })
  async fetchCompletionSitesAnalyticsData({ programId, siteId }: { programId: number; siteId: number }) {
    let response: any = '';
    if (siteId >= 0) {
      response = await programSitesGroupsList(programId, siteId);
    }
    return response;
  }

  @Action({ commit: 'mutateSiteGroupsAnalyticsList' })
  async fetchCompletionSiteGroupsAnalyticsData({ programId, siteId }: { programId: number; siteId: number }) {
    const groupIds: number[] = [];
    this.resetCompletionData();
    if (this.siteGroupsList && this.siteGroupsList.length) {
      this.siteGroupsList.forEach((group: any) => {
        if (group.checked) {
          groupIds.push(group.id);
        }
      });
      this.mutateSelectedGroupIds(groupIds);
    }
    const response: any = await completionSiteAnalyticsDetail(programId, siteId ? siteId : '', groupIds, this.selectedSiteSessionAnalyticsTab);
    return response;
  }

  @Mutation
  setAdaProgramPercentBlank() {
    this.adaProgramPercent = '--';
  }

  @Action({ commit: 'mutateAdaBarData' })
  async getAdaProgramData(prorgamId: number) {
    this.fileDownloadtrue();
    this.setAdaProgramPercentBlank();
    const response = await getAdaProgramData(prorgamId);
    return response;
  }

  @Mutation
  setretentionProgramPercentBlank() {
    this.retentionProgramPercent = '--';
  }

  @Mutation
  setcompletionProgramPercentBlank() {
    this.completionProgramPercent = '--';
  }

  @Action({ commit: 'mutateRetentionBarData' })
  async getRetentionProgramData(programId: number) {
    this.retensionFileDownloadtrue();
    this.setretentionProgramPercentBlank();

    const response = await getRetentionProgramData(programId);
    return response;
  }

  @Action
  async downloadCsv(arg: any) {
    switch (arg.key) {
      case 'ada_site':
        {

          const payload = {
            programId: this.selectedProgramData.programId
          };
          this.fileDownloadtrue();

          adabySite(payload).then(data => {
            if (data.status == 200) {
              this.fileDownloadfalse();
            }
          });
          break;
        }

      case 'ada_week':
        {
          const payload: CsvPayload_Week_Day = {
            programId: this.selectedProgramData.programId,
            siteId: this.siteSelected.id,
            groupIds: [],
            isDay: false
          };

          let isNoGroupSelected: boolean = false;
          isNoGroupSelected = Object.keys(this.siteGroupsData).every((element: any) => !this.siteGroupsData[element].checked);

          if (isNoGroupSelected) {
            payload.groupIds = [];
          }
          else {
            this.siteGroupsData.forEach((group: { id: any; checked: boolean }) => {
              if (group.checked) {
                payload.groupIds.push(group.id);
              }
            });
          }
          this.fileDownloadtrue();

          adabyWeek(payload).then(data => {
            if (data.status == 200) {
              this.fileDownloadfalse();
            }
          });
          break;
        }

      case 'ada_day':
        {
          const payload: CsvPayload_Week_Day = {
            programId: this.selectedProgramData.programId,
            siteId: this.siteSelected.id,
            groupIds: [],
            isDay: true
          };

          let isNoGroupSelected: boolean = false;
          isNoGroupSelected = Object.keys(this.siteGroupsData).every((element: any) => !this.siteGroupsData[element].checked);

          if (isNoGroupSelected) {
            payload.groupIds = [];
          }
          else {
            this.siteGroupsData.forEach((group: { id: any; checked: boolean }) => {
              if (group.checked) {
                payload.groupIds.push(group.id);
              }
            });
          }
          this.fileDownloadtrue();

          adabyDay(payload).then(data => {
            if (data.status == 200) {
              this.fileDownloadfalse();
            }
          });
          break;
        }

      case 'ada_checkin':
        {
          const payload: ICsvPayload = {
            programId: this.selectedProgramData.programId,
            siteId: this.siteSelected.id,
            groupIds: []
          };

          let isNoGroupSelected: boolean = false;
          isNoGroupSelected = Object.keys(this.siteGroupsData).every((element: any) => !this.siteGroupsData[element].checked);

          if (isNoGroupSelected) {
            payload.groupIds = [];
          }
          else {
            this.siteGroupsData.forEach((group: { id: any; checked: boolean }) => {
              if (group.checked) {
                payload.groupIds.push(group.id);
              }
            });
          }
          this.fileDownloadtrue();

          attendanceScholarCheckin(payload).then(data => {
            if (data.status == 200) {
              this.fileDownloadfalse();
            }
          });
          break;
        }

      case 'ada_checkout':
        {
          const payload: ICsvPayload = {
            programId: this.selectedProgramData.programId,
            siteId: this.siteSelected.id,
            groupIds: []
          };

          let isNoGroupSelected: boolean = false;
          isNoGroupSelected = Object.keys(this.siteGroupsData).every((element: any) => !this.siteGroupsData[element].checked);

          if (isNoGroupSelected) {
            payload.groupIds = [];
          }
          else {
            this.siteGroupsData.forEach((group: { id: any; checked: boolean }) => {
              if (group.checked) {
                payload.groupIds.push(group.id);
              }
            });
          }
          this.fileDownloadtrue();

          attendanceScholarCheckout(payload).then(data => {
            if (data.status == 200) {
              this.fileDownloadfalse();
            }
          });
          break;
        }

      case 'ada_scholar_details':
        {
          const payload: ICsvPayload = {
            programId: this.selectedProgramData.programId,
            siteId: this.siteSelected.id,
            groupIds: []
          };

          let isNoGroupSelected: boolean = false;
          isNoGroupSelected = Object.keys(this.siteGroupsData).every((element: any) => !this.siteGroupsData[element].checked);

          if (isNoGroupSelected) {
            payload.groupIds = [];
          }
          else {
            this.siteGroupsData.forEach((group: { id: any; checked: boolean }) => {
              if (group.checked) {
                payload.groupIds.push(group.id);
              }
            });
          }
          this.fileDownloadtrue();

          adaScholarDetailCSVDownload(payload).then(data => {
            if (data.status == 200) {
              this.fileDownloadfalse();
            }
          });
          break;
        }
      case 'ada_daily_attendance':
        {
          const payload: ICsvPayload = {
            programId: this.selectedProgramData.programId,
            siteId: this.siteSelected.id,
            groupIds: []
          };

          let isNoGroupSelected: boolean = false;
          isNoGroupSelected = Object.keys(this.siteGroupsData).every((element: any) => !this.siteGroupsData[element].checked);

          if (isNoGroupSelected) {
            payload.groupIds = [];
          }
          else {
            this.siteGroupsData.forEach((group: { id: any; checked: boolean }) => {
              if (group.checked) {
                payload.groupIds.push(group.id);
              }
            });
          }
          this.fileDownloadtrue();

          scholarDailyAttendanceCSVDownload(payload).then(data => {
            if (data.status == 200) {
              this.fileDownloadfalse();
            }
          });
          break;
        }
      default:
        break;
    }
  }

  @Mutation
  mutateEnrollmentSiteSession(data: { programId: any; site: any }) {
    const programId = data.programId;
    if (!Object.keys(this.enrollmentSettingObj).length) {
      this.enrollmentSettingObj = {
        [programId]: {
          selectedSite: data.site,
        }
      };
    }
    this.authKey = APP_UTILITIES.authTokenKeyToManage() ? APP_UTILITIES.authTokenKeyToManage() : '';
    if (this.enrollmentSettingObj.hasOwnProperty([programId])) {
      this.enrollmentSettingObj[programId] = {
        selectedSite: data.site,
      };
    }
    else if (!this.enrollmentSettingObj.hasOwnProperty([programId])) {
      const programData: any = {
        [programId]: {
          selectedSite: data.site,
        }
      };
      Object.assign(this.enrollmentSettingObj, JSON.parse(JSON.stringify(programData)));
    }
    APP_UTILITIES.setFilteredColumns(`${this.authKey}-enrollmentSiteSessionData`, this.enrollmentSettingObj);
  }

  @Mutation
  mutateApplicationApplicationName(data: { programId: any; application: any }) {
    const programId = data.programId;
    if (!Object.keys(this.applicationObj).length) {
      this.applicationObj = {
        [programId]: {
          selectedApplication: data.application,
        }
      };
    }
    this.authKey = APP_UTILITIES.authTokenKeyToManage() ? APP_UTILITIES.authTokenKeyToManage() : '';
    if (this.applicationObj.hasOwnProperty([programId])) {
      this.applicationObj[programId] = {
        selectedApplication: data.application,
      };
    }
    else if (!this.applicationObj.hasOwnProperty([programId])) {
      const programData: any = {
        [programId]: {
          selectedApplication: data.application,
        }
      };
      Object.assign(this.applicationObj, JSON.parse(JSON.stringify(programData)));
    }
    APP_UTILITIES.setFilteredColumns(`${this.authKey}-applicationapplicationname`, this.applicationObj);
  }

  @Action
  async downloadCsvCompletion(arg: any) {
    switch (arg.key) {
      case 'com_site':
        {
          const payload: any = {
            programId: this.selectedProgramData.programId,
            siteId: 0,
            groupIds: [],
            isDay: false,
            csvType: APP_CONST.ZERO

          };
          this.completionFileDownloadtrue();

          completionCSVDownload(payload).then(data => {
            if (data.status == 200) {
              this.completionFileDownloadfalse();
            }
          });
          break;
        }

      case 'com_week':
        {
          const payload: any = {
            programId: this.selectedProgramData.programId,
            siteId: this.selectedSite.id,
            groupIds: [],
            isDay: false,
            csvType: APP_CONST.ONE

          };

          let isNoGroupSelected: boolean = false;
          isNoGroupSelected = Object.keys(this.siteGroupsList).every((element: any) => !this.siteGroupsList[element].checked);
          if (isNoGroupSelected) {
            this.siteGroupsList.forEach((group: { id: any; checked: boolean }) => {
              payload.groupIds.push(group.id);
            });
          }
          else {
            this.siteGroupsList.forEach((group: { id: any; checked: boolean }) => {
              if (group.checked) {
                payload.groupIds.push(group.id);
              }
            });
          }
          this.completionFileDownloadtrue();

          completionCSVDownload(payload).then(data => {
            if (data.status == 200) {
              this.completionFileDownloadfalse();
            }
          });
          break;
        }

      case 'com_day':
        {
          const payload: any = {
            programId: this.selectedProgramData.programId,
            siteId: this.selectedSite.id,
            groupIds: [],
            isDay: true,
            csvType: APP_CONST.ONE
          };

          let isNoGroupSelected: boolean = false;

          isNoGroupSelected = Object.keys(this.siteGroupsList).every((element: any) => !this.siteGroupsList[element].checked);

          if (isNoGroupSelected) {
            this.siteGroupsList.forEach((group: { id: any; checked: boolean }) => {
              payload.groupIds.push(group.id);
            });
          }
          else {
            this.siteGroupsList.forEach((group: { id: any; checked: boolean }) => {
              if (group.checked) {
                payload.groupIds.push(group.id);
              }
            });
          }
          this.completionFileDownloadtrue();

          completionCSVDownload(payload).then(data => {
            if (data.status == 200) {
              this.completionFileDownloadfalse();
            }
          });
          break;
        }
    }
  }

  @Action
  async downloadCsvRetension(arg: any) {
    switch (arg.key) {
      case 'ret_site':
        {
          const payload: any = {
            programId: this.selectedProgramData.programId,
            siteId: 0,
            groupIds: [],
            isDay: APP_CONST.FALSE,
            csvType: APP_CONST.ZERO

          };
          this.retensionFileDownloadtrue();

          retensionCSVDownload(payload).then(data => {
            if (data.status == 200) {
              this.retensionFileDownloadfalse();
            }
          });
          break;
        }

      case 'ret_week':
        {
          const payload: any = {
            programId: this.selectedProgramData.programId,
            siteId: this.siteSelected.id,
            groupIds: [],
            isDay: APP_CONST.FALSE,
            csvType: APP_CONST.ONE

          };

          let isNoGroupSelected: boolean = false;
          isNoGroupSelected = Object.keys(this.siteGroupsData).every((element: any) => !this.siteGroupsData[element].checked);

          if (isNoGroupSelected) {
            this.siteGroupsData.forEach((group: { id: any; checked: boolean }) => {
              payload.groupIds.push(group.id);
            });
          }
          else {
            this.siteGroupsData.forEach((group: { id: any; checked: boolean }) => {
              if (group.checked) {
                payload.groupIds.push(group.id);
              }
            });
          }
          this.retensionFileDownloadtrue();

          retensionCSVDownload(payload).then(data => {
            if (data.status == 200) {
              this.retensionFileDownloadfalse();
            }
          });
          break;
        }

      case 'ret_day':
        {
          const payload: any = {
            programId: this.selectedProgramData.programId,
            siteId: this.siteSelected.id,
            groupIds: [],
            isDay: APP_CONST.TRUE,
            csvType: APP_CONST.ONE
          };

          let isNoGroupSelected: boolean = false;
          isNoGroupSelected = Object.keys(this.siteGroupsData).every((element: any) => !this.siteGroupsData[element].checked);

          if (isNoGroupSelected) {
            this.siteGroupsData.forEach((group: { id: any; checked: boolean }) => {
              payload.groupIds.push(group.id);
            });
          }
          else {
            this.siteGroupsData.forEach((group: { id: any; checked: boolean }) => {
              if (group.checked) {
                payload.groupIds.push(group.id);
              }
            });
          }
          this.retensionFileDownloadtrue();

          retensionCSVDownload(payload).then(data => {
            if (data.status == 200) {
              this.retensionFileDownloadfalse();
            }
          });
          break;
        }
    }
  }

  @Action
  async downloadCsvEnrollment(arg: any) {
    switch (arg.key) {
      case 'en_site':
        {
          const payload: any = {
            programId: this.selectedProgramData.programId,
            siteId: []
          };
          let isNoSitesSelected: boolean = false;
          isNoSitesSelected = Object.keys(this.programSitesData).every((element: any) => !this.programSitesData[element].checked);
          if (isNoSitesSelected) {
            this.programSitesData.forEach((site: { id: any; checked: boolean }) => {
              payload.siteId.push(site.id);
            });
          }
          else {
            this.programSitesData.forEach((site: { id: any; checked: boolean }) => {
              if (site.checked) {
                payload.siteId.push(site.id);
              }
            });
          }
          this.enrollmentFileDownloadtrue();

          enrollmentCSVDownload(payload).then(data => {
            if (data.status == 200) {
              this.enrollmentFileDownloadfalse();
            }
          });
          break;
        }
      case APP_CONST.DOWNLOAD_CONSOLIDATED_ROSTER_CSV_KEY:
        {

          const accountId: number = Number(APP_UTILITIES.getCookie('accountId'));
          const siteCode = (APP_UTILITIES.getCookie('siteId')) == null ? 0 : Number((APP_UTILITIES.getCookie('siteId')));
          const payload: any = {
            programId: this.selectedProgramData.programId,
            siteId: siteCode,
            accountId: accountId
          };
          this.consolidatedScholarRosterDownloadtrue();
          consolidatedScholarCSVDownload(payload).then(data => {
            if (data.status == 200) {
              this.consolidatedScholarRosterDownloadfalse();
            }
          });
          break;
        }
    }
  }

  @Mutation
  mutateRoleWiseUserCount(response: any) {
    if (response.status == APP_CONST.RESPONSE_200) {
      const counts: any = response.data.data;
      if (counts) {
        this.roleCounts[5] = counts[5] ? counts[5] : 0;
        this.roleCounts[6] = counts[6] ? counts[6] : 0;
        this.roleCounts[7] = counts[7] ? counts[7] : 0;
      }
    } else if (response.status == APP_CONST.RESPONSE_400) {
      this.roleCounts = { 5: 0, 6: 0, 7: 0 };
    } else if (response.status == APP_CONST.RESPONSE_204) {
      this.roleCounts = { 5: 0, 6: 0, 7: 0 };
    }
    return response;
  }

  @Action({ commit: 'mutateRoleWiseUserCount' })
  async getRoleWiseUserCount(programIds: number[]) {
    const response: any = await getRoleWiseUsersByProgramId(programIds);
    return response;
  }

  @Mutation
  mutateProfLearningData(response: any) {
    if (response.status == APP_CONST.RESPONSE_200) {
      this.categories = [];
      this.completeProfLearningData = { datasets: response.data.dataSet, labels: response.data.labels };

      if (this.completeProfLearningData && this.completeProfLearningData.labels && !this.completeProfLearningData.labels.length) {
        this.disableButtonProfessionalLearning = true;
      }
      if (this.completeProfLearningData && this.completeProfLearningData.labels && this.completeProfLearningData.labels.length) {
        this.disableButtonProfessionalLearning = false;
        this.completeProfLearningData.labels.forEach((label: any, index: number) => {
          const labelName: string = label[0];
          this.categories.push({ id: labelName, value: labelName, checked: false });
          this.completeProfLearningData.datasets.forEach((ds: any) => {
            const isExists = ds.data.findIndex((x: any) => x.x.toString() == labelName) > -1;
            if (!isExists) {
              ds.data.splice(index, 0, { x: labelName, y: null, numerator: 0, denominator: 0 });
            }
          });
        });


      }
    } else if (response.status == APP_CONST.RESPONSE_400) {
      this.completeProfLearningData = { labels: [], datasets: [] };
    } else if (response.status == APP_CONST.RESPONSE_204) {
      this.completeProfLearningData = { labels: [], datasets: [] };
    }
    return response;
  }

  @Action({ commit: 'mutateProfLearningData' })
  async getProfLearningData(programIds: number[]) {
    this.clearCategories();
    this.professionalLearningFileDownloadtrue();
    const response: any = await getProfessionalLearningDetails(programIds);
    return response;
  }

  @Mutation
  locallyStoreQualitySite(data: { programId: any; site: any }) {
    const programId = data.programId;
    if (!Object.keys(this.qualitySetitngsObj).length) {
      this.qualitySetitngsObj = {
        [programId]: {
          selectedSite: data.site,
        }
      };
    }
    this.authKey = APP_UTILITIES.authTokenKeyToManage() ? APP_UTILITIES.authTokenKeyToManage() : '';
    if (this.qualitySetitngsObj.hasOwnProperty([programId])) {
      this.qualitySetitngsObj[programId] = {
        selectedSite: data.site,
      };
    }
    else if (!this.qualitySetitngsObj.hasOwnProperty([programId])) {
      const programData: any = {
        [programId]: {
          selectedSite: data.site,
        }
      };
      Object.assign(this.qualitySetitngsObj, JSON.parse(JSON.stringify(programData)));
    }
    APP_UTILITIES.setFilteredColumns(`${this.authKey}-qualitySiteSessionData`, this.qualitySetitngsObj);
  }

  @Mutation
  mutateQualitySettingObj(qualityData: any) {
    this.qualitySetitngsObj = qualityData;
  }

  @Action
  async getQualityPersistence(data: { programId: number }) {
    const authKey = APP_UTILITIES.authTokenKeyToManage() ? APP_UTILITIES.authTokenKeyToManage() : '';
    const drillDownSiteId: any = APP_UTILITIES.getCookie('siteId');
    if (drillDownSiteId && drillDownSiteId > 0) {
      const siteResponse = await programSites(data.programId);
      siteResponse.data.forEach((site: any) => {
        if (site.siteId == drillDownSiteId) {
          this.mutateSelectedSite({ id: site.siteId, value: site.siteName });
        }
      });
    }
    if (APP_UTILITIES.getFilteredColumns(`${authKey}-qualitySiteSessionData`)) {
      let qualityData: any = APP_UTILITIES.getFilteredColumns(`${authKey}-qualitySiteSessionData`);
      qualityData = JSON.parse(qualityData);
      this.mutateQualitySettingObj(qualityData);
      if (qualityData.hasOwnProperty([data.programId])) {
        const siteResponse = await programSites(data.programId);
        const siteIds: any[] = [];
        siteResponse.data.forEach((site: any) => { siteIds.push(site.siteId); });
        if (siteIds.includes(qualityData[data.programId].selectedSite.id)) {
          this.mutateSelectedSite(qualityData[data.programId].selectedSite);
        }
        else {
          this.mutateSelectedSite({ id: 0, value: 'All Site/Sessions' });
        }
      }
    }

  }


  @Mutation
  locallyStoreProfLearningData(data: { programId: any; categories: any; roles: any }) {
    const programId = data.programId;
    if (!Object.keys(this.profLearningSettingsObj).length) {
      this.profLearningSettingsObj = {
        [programId]: {
          selectedCourses: data.categories,
          selectedRoles: data.roles
        }
      };
    }
    this.authKey = APP_UTILITIES.authTokenKeyToManage() ? APP_UTILITIES.authTokenKeyToManage() : '';
    if (this.profLearningSettingsObj.hasOwnProperty([programId])) {
      this.profLearningSettingsObj[programId] = {
        selectedCourses: data.categories,
        selectedRoles: data.roles
      };
    }
    else if (!this.profLearningSettingsObj.hasOwnProperty([programId])) {
      const programData: any = {
        [programId]: {
          selectedCourses: data.categories,
          selectedRoles: data.roles
        }
      };
      Object.assign(this.profLearningSettingsObj, JSON.parse(JSON.stringify(programData)));
    }
    APP_UTILITIES.setFilteredColumns(`${this.authKey}-professionalLearningData`, this.profLearningSettingsObj);
  }

  @Mutation
  getProfLearningPersistence(data: { programId: number }) {
    const authKey = APP_UTILITIES.authTokenKeyToManage() ? APP_UTILITIES.authTokenKeyToManage() : '';
    if (APP_UTILITIES.getFilteredColumns(`${authKey}-professionalLearningData`)) {
      let professionalLearningData: any = APP_UTILITIES.getFilteredColumns(`${authKey}-professionalLearningData`);
      professionalLearningData = JSON.parse(professionalLearningData);
      this.profLearningSettingsObj = professionalLearningData;
      if (professionalLearningData.hasOwnProperty([data.programId])) {
        let coursesList = [];
        coursesList = this.categories;
        coursesList.forEach((latestCourse: any) => {
          professionalLearningData[data.programId].selectedCourses.forEach((stored: any) => {
            if (latestCourse.id == stored.id) {
              latestCourse.checked = stored.checked;
            }
          });
        });
        this.categories = coursesList;
        this.profLearningRoles = professionalLearningData[data.programId].selectedRoles;
      } else {
        this.profLearningRoles = JSON.parse(JSON.stringify(APP_CONST.PROF_LEARNING_ROLES_LIST));
      }
    }
  }

  @Action
  async downloadCsvProfessionalLearning(programIds: Array<number>) {
    const payload: any = {
      programIds: programIds,
    };

    this.professionalLearningFileDownloadtrue();

    professionalLearningCSVDownload(payload).then(data => {
      if (data.status == 200) {
        this.professionalLearningFileDownloadfalse();
      }
    });
  }
}

export default getModule(AnalyticsModule);
